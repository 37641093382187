import {useParams} from "react-router-dom";
import {languagesData} from "../constants/languages";

export const useCurrentLangCode = () => {
    const {lang} = useParams();

    const currentLangCode = languagesData.find(item => item.code === lang?.toLowerCase());
    const langForInUrl = Boolean(currentLangCode) ? (currentLangCode.code === "nl" ? "" : `/${currentLangCode.code}`) : "";

    return { currentLangCode, langForInUrl };
};