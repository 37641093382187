import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ParallaxProvider } from 'react-scroll-parallax';
import PopupLinkProvider from "./contexts/PopupLinkContext";
import './i18n';
import * as Sentry from "@sentry/react";
import {CaptureConsole, HttpClient} from "@sentry/integrations";
import {createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType} from "react-router-dom";
import Root, {rootLoader, shouldNotRevalidateParentOnSearchParamChange} from "./routes/Root/Root";
import ErrorPage from "./routes/ErrorPage/ErrorPage";
import Home, {homeLoader} from "./routes/Home/Home";
import Functionalities, {functionalitiesLoader} from "./routes/Functionalities/Functionalities";
import {FunctionalityPopup, functionalityPopupLoader} from "./routes/FunctionalityPopup/FunctionalityPopup";
import BlogsAndLabs, {blogsAndLabsLoader} from "./routes/BlogsAndLabs/BlogsAndLabs";
import {BlogsAndLabsDetail, blogsAndLabsDetailLoader} from "./routes/BlogsAndLabsDetail/BlogsAndLabsDetail";
import Cases, {casesLoader} from "./routes/Cases/Cases";
import CasesDetail, {casesDetailLoader} from "./routes/CasesDetail/CasesDetail";
import Pricing, {pricingLoader} from "./routes/Pricing/Pricing";
import About, {aboutLoader} from "./routes/About/About";
import {YoumieNetwork, youmieNetworkLoader} from "./routes/YoumieNetwork/YoumieNetwork";
import Contact, {contactLoader} from "./routes/Contact/Contact";
import Policy, {policyLoader} from "./routes/Policy/Policy";
import PrivacyUVSV from "./routes/Privacy/PrivacyUVSV";
import PrivacyVindicat from "./routes/Privacy/PrivacyVindicat";
import {oldUrls, redirectPermanently} from "./helpers/redirectPermanently";
import MarketingPopupProvider from "./contexts/MarketingPopupContext";
import BreakpointProvider from "./contexts/BreakpointContext";
import {langCheckLoader} from "./routes/LangCheck/LangCheck";

Sentry.init({
    dsn: "https://eb8f843fae3d52cac9f77ae6563cac78@o72372.ingest.sentry.io/4506070822879232",
    release: 'youmie-student-react@1.0.0',
    environment: process.env.REACT_APP_ENVIRONMENT_TYPE === 'test' ? "test" : process.env.REACT_APP_ENVIRONMENT_TYPE === 'production' ? "production" : "development",
    attachStacktrace: true,
    ignoreErrors: [/Deferred data aborted/, /Failed to fetch/, /The user aborted a request/],
    allowUrls: [/https?:\/\/(localhost:\d+|youmie\.nl)(\/.*)?/],
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new Sentry.Replay(),
        new CaptureConsole({
            levels: ["info", "error", "debug", "assert"],
        }),
        new HttpClient(),
    ],
    sendDefaultPii: true, // Capture headers and cookies (for HttpClient)
    tracesSampleRate: 0.1, // Capture 10% of the transactions (for BrowserTracing/Performance Monitoring)
    replaysSessionSampleRate: 0.1, // Set the sample rate to 10% (for Session Replay)
    replaysOnErrorSampleRate: 1.0, // Set the sample rate to 100% when sampling sessions where errors occur (for Session Replay)
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
    {
        path: '/:lang?',
        element: <Root />,
        loader: rootLoader,
        shouldRevalidate: shouldNotRevalidateParentOnSearchParamChange,
        id: "root",
        errorElement: <ErrorPage />,
        children: [
            {
                loader: langCheckLoader,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <Home />,
                        loader: homeLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'functionaliteiten',
                        element: <Functionalities />,
                        loader: functionalitiesLoader,
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: ':slug',
                                element: <FunctionalityPopup />,
                                loader: functionalityPopupLoader,
                            },
                        ],
                    },
                    {
                        path: 'blog',
                        element: <BlogsAndLabs />,
                        loader: blogsAndLabsLoader,
                        shouldRevalidate: shouldNotRevalidateParentOnSearchParamChange,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'blog/:slug',
                        element: <BlogsAndLabsDetail />,
                        loader: blogsAndLabsDetailLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'cases',
                        element: <Cases />,
                        loader: casesLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'cases/:slug',
                        element: <CasesDetail />,
                        loader: casesDetailLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'case/:slug',
                        element: <CasesDetail />,
                        loader: casesDetailLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'prijzen/:pricingPageType?',
                        element: <Pricing />,
                        loader: pricingLoader,
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: ':slug',
                                element: <FunctionalityPopup />,
                                loader: functionalityPopupLoader,
                            },
                        ],
                    },
                    {
                        path: 'over-youmie',
                        element: <About />,
                        loader: aboutLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'youmie-network',
                        element: <YoumieNetwork />,
                        loader: youmieNetworkLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'contact',
                        element: <Contact />,
                        loader: contactLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'algemene-voorwaarden',
                        element: <Policy />,
                        loader: policyLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'privacyverklaring',
                        element: <Policy />,
                        loader: policyLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'cookiebeleid',
                        element: <Policy />,
                        loader: policyLoader,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'app/:slug',
                        element: <Policy />,
                        loader: policyLoader,
                        errorElement: <ErrorPage />,
                    },
                    // TODO: Dit zijn tijdelijke privacy statements, dit moeten ook app policies worden, evt. met redirect vanaf deze url
                    {
                        path: 'privacy-statement-uvsv',
                        element: <PrivacyUVSV />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: 'privacy-statement-vindicat',
                        element: <PrivacyVindicat />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: '*',
                        element: <ErrorPage />,
                    },
                ],
            },
        ],
    },
    ...oldUrls.map(item => (
        {
            path: item.oldPath,
            loader: () => {
                return redirectPermanently(item.newPath)
            },
            errorElement: <ErrorPage />
        }
    )),
]); 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        {/*<React.StrictMode>*/}
        <HelmetProvider>
            <ParallaxProvider>
                <MarketingPopupProvider>
                    <PopupLinkProvider>
                        <BreakpointProvider>
                            <RouterProvider router={router} />
                        </BreakpointProvider>
                    </PopupLinkProvider>
                </MarketingPopupProvider>
            </ParallaxProvider>
        </HelmetProvider>
        {/*</React.StrictMode>*/}
    </>
);