import styled from "styled-components";

export const Grid = styled.section`
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    padding-bottom: 50px;

    @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        padding-bottom: 30px;
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 32px;
        padding-bottom: 10px;
    }
`;

export const VerticalImageOuter = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 2 / 3;
    background-color: var(--color-white);
    border-radius: 15px;
    overflow: hidden;
    
    @supports not (aspect-ratio: 9 / 16) {
        padding-top: 66.67%;
    }
`;

export const VerticalImageInner = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @supports not (aspect-ratio: 9 / 16) {
        position: absolute;
        top: 0;
    }
`;

export const StyledTitle = styled.h3`
    font-family: var(--font-heading);
    line-height: 1.4;
    margin: 16px 0 0 0;
    font-size: 2rem;
    font-weight: var(--fw-bold);

    @media screen and (max-width: 525px) {
        font-size: 1.8rem;
    }
`;