import { Helmet } from 'react-helmet-async';
import * as S from './Privacy.elements';
import Wrapper from "../../components/Wrapper/Wrapper";
import Container from "../../components/Container/Container";
import Heading from "../../components/Heading/Heading";

const PrivacyVindicat = () => {

    return (
        <>
            <Helmet>
                <title>Youmie &mdash; Privacyverklaring Vindicat en SOL</title>
                <meta property="og:title" content="Youmie - Privacyverklaring Vindicat en SOL" />
                <meta name="twitter:title" content="Youmie - Privacyverklaring Vindicat en SOL" />
                <link rel="canonical" href="https://youmie.nl/privacy-statement-vindicat" />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Wrapper>
                <Container>
                </Container>
                <S.StyledContainer>
                    <Heading tag="h2">Vindicat en SOL</Heading>

                    <S.Section>
                        <S.Text>Persoonsgegevens zijn gegevens over een geïdentificeerde of identificeerbare natuurlijke persoon. Vindicat atque Polit (hierna: Vindicat) heeft oog voor een zorgvuldige omgang met persoonsgegevens. Zij is verantwoordelijk voor de verwerking van de persoonsgegevens van de volgende categorieën personen voor de bijbehorende doelen:</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">1. (Aspirant-)leden en buitengewoon leden van Vindicat</Heading>
                        <S.Text>Vindicat verwerkt persoonsgegevens van (aspirant)leden en buitengewoon leden ten behoeve van:</S.Text>
                        <S.List>
                            <S.Item>het bevorderen van de ontwikkeling en ontplooiing van haar leden op algemeen, maatschappelijk, cultureel en sportief gebied;</S.Item>
                            <S.Item>het bieden van de mogelijkheden tot onderlinge kennismaking en vriendschappelijke omgang van haar leden;</S.Item>
                            <S.Item>het behartigen in de ruimste zin des woords van alle overige belangen van haar leden en de andere studerenden.</S.Item>
                        </S.List>
                        <S.Text>Vindicat verwerkt voor die doeleinden de volgende gegevens:</S.Text>
                        <S.List>
                            <S.Item>naam, adres en woonplaats (NAW-gegevens);</S.Item>
                            <S.Item>geboortedatum;</S.Item>
                            <S.Item>telefoonnummer;</S.Item>
                            <S.Item>e-mailadres;</S.Item>
                            <S.Item>rekeningnummer</S.Item>
                            <S.Item>jaar van aankomst</S.Item>
                            <S.Item>pasfoto</S.Item>
                            <S.Item>studierichting en studienummer</S.Item>
                            <S.Item>het telefoonnummer van ouder(s)/verzorger(s)</S.Item>
                            <S.Item>het adres en de woonplaats van uw ouder(s)/verzorger(s)</S.Item>
                        </S.List>
                        <S.Text>Aan aspirant-leden wordt gevraagd bij de inschrijving een aantal bijzondere persoonsgegevens te vermelden, zoals allergieën en beperkingen. Doel is het kunnen bieden van een goede begeleiding tijdens de introductietijd. Na afloop van de introductietijd worden deze gegevens vernietigd.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">2. Oud-leden en reünisten van Vindicat en donateurs van de SOL</Heading>
                        <S.Text>Samen met de Stichting Oud-Leden (SOL) is Vindicat verantwoordelijk voor het verwerken van persoonsgegevens van oud-leden en reünisten van Vindicat en donateurs van de SOL. Doelen zijn:</S.Text>
                        <S.List>
                            <S.Item>het stimuleren en coördineren van de activiteiten van oud-leden en reünisten</S.Item>
                            <S.Item>het onderhouden van contacten met Vindicat</S.Item>
                            <S.Item>het ondersteunen van Vindicat</S.Item>
                            <S.Item>het bevorderen van de contacten tussen de oud-leden en reünisten onderling, tussen de oud-ledenen, reünisten en de leden van Vindicat, alsook tussen zijn leden onderling</S.Item>
                            <S.Item>alsmede al hetgeen met het vorenstaande in de ruimste zin verband houdt of daartoe bevorderlijk kan zijn.</S.Item>
                        </S.List>
                        <S.Text>Het gaat om de volgende gegevens:</S.Text>
                        <S.List>
                            <S.Item>naam, adres en woonplaats (NAW-gegevens);</S.Item>
                            <S.Item>geboortedatum;</S.Item>
                            <S.Item>telefoonnummer;</S.Item>
                            <S.Item>e-mailadres;</S.Item>
                            <S.Item>rekeningnummer</S.Item>
                            <S.Item>jaar van aankomst</S.Item>
                            <S.Item>pasfoto</S.Item>
                        </S.List>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">3. Derden/overige</Heading>
                        <S.Text>Vindicat verwerkt persoonsgegevens van derden ten behoeve van communicatie met deze personen of andere specifieke doeleinden waarvoor het contact met deze derden wordt gelegd. Het gaat om:</S.Text>
                        <S.List>
                            <S.Item>Derden, waaronder leveranciers en contactpersonen bij andere organisaties ten behoeve van de bedrijfsvoering en ondersteuning van Vindicat en de SOL en het kunnen onderhouden van contact met voor Vindicat en SOL relevante organisaties: NAW, telefoonnummer, e-mailadres en functie van de betrokkene.</S.Item>
                            <S.Item>Personen die een webformulier invullen: de gegevens die degene zelf invult op het webformulier waaronder de gevraagde contactgegevens.</S.Item>
                        </S.List>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">Foto's</Heading>
                        <S.Text>Binnen de Sociëteit ‘Mutua Fides’ mogen foto’s alleen worden gemaakt door de commissie Kroegfotografen of een specifiek ingehuurd bedrijf. Doel van deze foto’s is het creëren van sfeerimpressies voor de leden of voor promotiedoeleinden, enkel voor intern gebruik. De foto’s worden beheerd door de Senaat. De Senaat behoudt te allen tijde het recht om foto’s definitief te laten verwijderen. Wanneer leden de Senaat vragen om foto’s van hen te verwijderen wordt hier per direct gehoor aan gegeven.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">Online almanak</Heading>
                        <S.Text>Vindicat en de SOL faciliteren via de respectievelijke websites een online almanak. Deze is alleen toegankelijk voor leden en donateurs met de benodigde inloggegevens. De gebruikers hebben de keuze voor de mate waarin hun persoonsgegevens zichtbaar zijn voor anderen. Pasfoto’s in de online almanak worden alleen geplaatst met toestemming van de betrokkene dan wel door de betrokkene zelf.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">Grondslag voor de verwerkingen</Heading>
                        <S.Text>Vindicat en SOL verwerken de persoonsgegevens op grond van het gerechtvaardigd belang dat Vindicat en SOL hierbij hebben om de benoemde doelen te bereiken, gelet op de doeleinden die beide nastreven en het belang van de betrokkenen daarbij.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">Bewaartermijn van de persoonsgegevens</Heading>
                        <S.Text>Vindicat en SOL bewaren de persoonsgegevens zo lang als nodig is om de doelen te realiseren waarvoor de gegevens worden verzameld. Wanneer het lidmaatschap van Vindicat wordt beëindigd en een lid oud-lid of reünist en/of donateur wordt, is de SOL met Vindicat gemeenschappelijk verantwoordelijk voor de zorgvuldige omgang met die gegevens.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">Verstrekking aan derden</Heading>
                        <S.Text>De persoonsgegevens van leden van Vindicat kunnen worden gedeeld met jaarcommissarissen, kolonie gouverneurs, sub-verenigingen en de huizen die aangesloten zijn bij Vindicat op grond van het gerechtvaardigd belang dat Vindicat, koloniën, sub-verenigingen en de huizen daarbij hebben in het specifieke geval.</S.Text>
                        <S.Text>Vindicat verstrekt persoonsgegevens van leden ten behoeve van het versturen van De Vindicat aan de drukker. Voor het overige worden deze gegevens niet aan overige derden verstrekt zonder toestemming van deze leden, tenzij hieraan een wettelijke verplichting ten grondslag ligt. De leden worden over het verstrekken van hun gegevens geïnformeerd.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">Beveiliging</Heading>
                        <S.Text>Vindicat neemt de bescherming van de persoonsgegevens waarvoor zij verantwoordelijk is serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met de Senaat via <S.Url href='mailto:rector@vindicat.nl'>rector@vindicat.nl</S.Url>. Voor oud-leden, reünisten en donateurs kunnen hiervoor eveneens contact opnemen met het bestuur van de SOL via sol@vindicat.nl</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">Rechten</Heading>
                        <S.Text>U heeft het recht om uw persoonsgegevens in te zien, te (laten) corrigeren of te (laten) verwijderen. Leden kunnen dit zelf doen via de persoonlijke instellingen van hun account op de website <S.Url href='https://www.vindicat.nl' target='_blank' rel='noreferrer'>www.vindicat.nl</S.Url>. Donateurs van de SOL kunnen hun persoonsgegevens inzien, corrigeren, aanvullen en verwijderen via de persoonlijke instellingen van hun account op de website <S.Url href='https://www.reunistenvindicat.nl' target='_blank' rel='noreferrer'>www.reunistenvindicat.nl</S.Url>.</S.Text>
                        <S.Text>Daarnaast heeft u het recht om uw toestemming voor de verwerking van foto’s in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door Vindicat en de SOL. Waar het gaat om de foto’s heeft u tevens het recht op gegevensoverdraagbaarheid.</S.Text>
                        <S.Text>Wilt u gebruik maken van uw recht uw toestemming in te trekken, uw recht op bezwaar of recht op gegevensoverdraagbaarheid of heeft u andere vragen of opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd verzoek naar <S.Url href='mailto:rector@vindicat.nl'>rector@vindicat.nl</S.Url>. Om er zeker van te zijn dat het verzoek door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs bij het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. Vindicat zal zo snel mogelijk, maar in ieder geval binnen vier weken, op uw verzoek reageren.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">Klachten</Heading>
                        <S.Text>Vindicat wil u er tevens op wijzen dat u de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: <S.Url href='https://autoriteitpersoonsgegevens.nl/een-tip-of-klacht-indienen-bij-de-ap' target='_blank' rel='noreferrer'>https://autoriteitpersoonsgegevens.nl/een-tip-of-klacht-indienen-bij-de-ap</S.Url></S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">Cookies</Heading>
                        <S.Text>Vindicat en de SOL respecteren de privacy van alle gebruikers van de website <S.Url href='https://www.vindicat.nl' target='_blank' rel='noreferrer'>www.vindicat.nl</S.Url> en www.reunistenvindicat.nl. Vindicat en SOL zijn altijd op zoek naar mogelijkheden om de websites te verbeteren en om deze zoveel mogelijk af te stemmen op de behoeften van de bezoekers. De websites maken daarbij gebruik van functionele cookies.</S.Text>
                        <S.Text>Vindicat en SOL zijn niet verantwoordelijk of aansprakelijk voor het cookiegebruik van derden, zoals de verantwoordelijkheden van de websites waarnaar de bezoeker kan doorlinken of de bezoeker anderszins zal gebruiken zoals aanbieders van social media waaronder YouTube, Twitter, Linkedin, Facebook, Instagram en dergelijke.</S.Text>
                    </S.Section>
                </S.StyledContainer>
            </Wrapper>

        </>
    );
}

export default PrivacyVindicat
