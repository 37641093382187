import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import {RichText} from "../Text/Text";

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
    margin: 0 -40px;

    @media screen and (max-width: 1024px) {
        flex-direction: column; 
        margin: 0;
    }
`;

export const Box = styled.section`
    margin: 0 40px;
    flex: 0 1 50%;

    @media screen and (max-width: 1024px) {
        margin: 0;
    }
`;

export const Decoration = styled.img`
    width: 100px;
    height: 50px;
    margin-bottom: 40px;
`;

export const Squiggly = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and (max-width: 1024px) {
        width: 30%;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const ParallaxGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 12px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-top: 40px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

export const ParallaxItem = styled(Parallax)`
    &:first-of-type {
        margin-top: 150px;
    }
    article {
        margin-bottom: 12px;
    }

    @media screen and (max-width: 1024px) {
        &:first-of-type {
            margin-top: 0;
        }
    }
`;

export const StyledRichText = styled(RichText)`
    margin-bottom: 40px;
`;