import styled from 'styled-components';
import { motion } from "framer-motion"; 

export const Hero = styled.section`
    position: relative;
    padding-top: 200px;
    overflow: hidden;
    padding-bottom: 300px;
    background-color: var(--color-surface);

    @media screen and (max-width: 1024px) {
        padding-top: 150px;
    }

    @media screen and (max-width: 768px) {
        padding-bottom: 250px;
    }
`;

export const Flex = styled.div`
    display: flex;
    height: 100%;
    align-items: center;

    @media screen and (max-width: 768px){
        flex-direction: column;
        padding-bottom: 80px;
    }
`;

export const HeroText = styled.div`
    flex: 1;

    @media screen and (max-width: 768px){
        text-align: center;
        margin-bottom: 80px;
    }
`;

export const ButtonWrapper = styled.div`
    padding-top: 32px;

    a:not(:last-of-type) {
        margin-right: 8px;
        margin-bottom: 8px;
    }
`;

export const DemoPhone = styled.div`
    flex: 1;
    margin: 0 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const PhoneWrapper = styled.div`
    
    @media screen and (max-width: 992px) {
        position: relative;
    } 

    @media screen and (max-width: 425px) {
        transform: translateX(-25px);
    }
`;

export const DecorationBorderCircles = styled.img`
    bottom: -240px;
    left: -160px;
    position: absolute;
    width: 500px;
    height: 500px;
    transition: width .2s ease, height .2s ease, bottom .2s ease;

    @media screen and (max-width: 1024px) {
        width: 400px;
        height: 400px;
    }
    
    @media screen and (max-width: 768px) {
        bottom: 300px;
    }

    @media screen and (max-width: 375px) {
        bottom: -70px;
        width: 350px;
        height: 350px;
    }
`;

export const Preview = styled.img`
    display: block;
    width: 285px;
    max-width: 100%;
    height: 598px;
    max-height: 100%;
    background-color: #FBFBFB;
    border-radius: 30px;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.05);
    border: 8px solid var(--color-white);
    object-fit: cover;
    object-position: top;

    @media screen and (max-width: 375px) {
        width: 250px;
        height: 522px;
    }
`;

export const OptionList = styled.ul`
    position: absolute;
    bottom: -40px;
    right: 0;
    list-style: none;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 250px;
    background-color: var(--color-white);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    transition: all 0.2s ease;

    @media screen and (max-width: 992px) {
        width: 110px;
        right: -50px;
        bottom: 50%;
        transform: translateY(50%);
    }
`;

export const StyledItem = styled.li`
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
`;

export const ItemImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
`;

export const Outline = styled(motion.div)`
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 5px solid white;
    border-radius: 50%;
`;