import styled, { keyframes } from 'styled-components';

export const LoadingContainer = styled.section`
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100vh;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #D4E7D5;
    z-index: 99;
`;

const spinner = keyframes`
    0%, 20%, 80%, 100% {
        transform: scale(1.5);
    }
    50% {
        transform: scale(1);
    }
`;

export const Spinner = styled.div`
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;

    span {
        position: absolute;
        width: 5px;
        height: 5px;
        background: #4BC17A;
        border-radius: 50%50%;
        animation: ${spinner} .9s linear infinite;

        &:nth-child(1) {
            animation-delay: 0s;
            width: 12px;
            height: 12px;
            top: 70px;
            left: 35px;
        }

        &:nth-child(2) {
            animation-delay: -0.1s;
            width: 14px;
            height: 14px;
            top: 47px;
            left: 46px;
        }

        &:nth-child(3) {
            animation-delay: -0.2s;
            width: 16px;
            height: 16px;
            top: 21px;
            left: 54px;
        }

        &:nth-child(4) {
            animation-delay: -0.3s;
            width: 13px;
            height: 13px;
            top: 29px;
            left: 28px;
        }

        &:nth-child(5) {
            animation-delay: -0.4s;
            width: 6px;
            height: 6px;
            top: 54px;
            left: 32px;
        }
    }
`;

const loadingtext = keyframes`
    0%, 100%   { content: ''; }
    12.5%, 75%  { content: '.'; }
    25%, 62.5%  { content: '..'; }
    50%  { content: '...'; }
`;

export const LoadingText = styled.p`
    font-size: 1.6rem;
    color: #04575D;
    font-weight: 600;

    &:after {
        display: inline-block;
        animation: ${loadingtext} 4s infinite;
        content: '';
    }
`;