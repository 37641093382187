import React from 'react';
import * as S from './BlogsAndLabsDetail.elements';
import {useLoaderData} from "react-router-dom";
import Container from "../../components/Container/Container";
import {Paragraphs} from "../../components/Paragraphs/Paragraphs";
import {Seo} from "../../components/Seo/Seo";
import Hero from "../../components/Hero/Hero";
import Text from "../../components/Text/Text";
import {useTranslation} from "react-i18next";
import {Tag} from "../../components/Tag/Tag";

export async function blogsAndLabsDetailLoader({request, params}) {
    const lang = params?.lang;

    const res = await fetch(`${process.env.REACT_APP_API_URL}/blogs/${params?.slug}${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page is not found", { status: 404 });
    }

    const data = await res.json();

    return {data};
}


export const BlogsAndLabsDetail = () => {
    const {data} = useLoaderData();
    const {t} = useTranslation();

    return (
        <>
            <Seo
                title={data?.title}
                description={data?.text}
                slug={`/blog/${data?.slug}`}
                image={data?.imageUrl}
            />

            <Hero
                type="overlap" $small
                superTitle={<Text textOnSurface>{data?.date} <Tag $onSurface>{t(`blog.type.${data?.type ? data.type : "blog"}`)}</Tag></Text>}
                heading={data?.title}
                text={data?.text}
                supportWordBreak={true}
            />

            <S.HeroOverlap>
                <Container>
                    <S.Outer>
                        <S.Inner src={data?.imageUrl} alt="" />
                    </S.Outer>
                </Container>
            </S.HeroOverlap>

            <Paragraphs data={data?.paragraphs} />
        </>
    );
}