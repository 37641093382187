import styled from 'styled-components';
import Container from "../Container/Container";

export const Wrapper = styled(Container)`
    padding-top: 80px;
    padding-bottom: 80px;
`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        text-align: center;
        flex-direction: column;
    }
`;

export const Box = styled.div`
    flex: 0 0 33.33%;
    border-right: 5px solid var(--color-primary);
    padding-right: 16px;
    margin-right: 60px;

    @media screen and (max-width: 768px) {
        border: none;
        padding: 0;
        margin: 0;
    }
`;