import styled from "styled-components";
import Slider from 'react-slick';

export const AboutContainer = styled.div`
    margin-top: -280px;
    position: relative;
    margin-bottom: 80px;
    z-index: 95;
`;

export const Grid = styled.div`
    display: grid;
	grid-template-columns: repeat(4, minmax(200px, 1fr));
    grid-auto-rows: 200px;
    grid-gap: 8px;
    grid-auto-flow: dense;
`;

export const Item = styled.div`
    overflow: hidden;
    border-radius: 8px;
    
    &.horizontal { grid-column: span 2; }
    &.vertical { grid-row: span 2; }
`;

export const Img = styled.img`
    border-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform .2s ease;
    
    &:hover {
        transform: scale(1.05);
    }
`;

export const StyledSlider = styled(Slider)`
    width: 100%;
    height: 280px;
    overflow: hidden;
    border-radius: 14px;
    
    .slick-dots {
        bottom: 20px;
        left: 50%;
        top: unset;
        width: unset;
        transform: translateX(-50%);

        li {
            display: inline-block;
            transition: all .2s;
            width: 10px;
            height: 10px;

            button {
                transition: all .2s;
                background-color: var(--color-white);
                border-radius: 5px;
                width: 10px;
                height: 10px;

                &:before {
                    content: none;
                }
            }

            &.slick-active {
                transition: all .2s;
                width: 30px;

                button {
                    background-color: var(--color-primary);
                    width: 30px;
                }
            }
        }
    }

    .slick-slide {
        border: none;
        
        div {
            outline: none;
        }
    }
`;

export const Slide = styled.div`
    height: 280px;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const CoreValues = styled.section`
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    
    @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const Spacing = styled.div`
    padding: 80px 0;
    
    @media screen and (max-width: 768px) {
        padding: 40px 0;
    }
`;