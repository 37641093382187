import styled from 'styled-components';

export const Section = styled.section`
    display: flex;
    flex-direction: column;
`;

export const Accordion = styled.button`
    /* border-top: 2px solid var(--color-stroke); */
    border-bottom: 2px solid var(--color-stroke);
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    outline: none;
    transition: background-color 0.6s ease;

    &:hover, &.active {
        background-color: var(--color-stroke);
    }

    .accordion__icon {
        margin-left: auto;
        transition: transform 0.6s ease;
    }

    .rotate {
        transform: rotate(90deg);
    }
`;

export const Content = styled.div`
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    padding-left: 18px;
`;