import React from 'react';
import * as S from './Footer.elements';
import { HiArrowSmRight, HiOutlinePhone, HiOutlineMail, HiOutlineLocationMarker } from 'react-icons/hi';
import { FaInstagram } from 'react-icons/fa';
import { RiFacebookCircleLine } from 'react-icons/ri';
import { LuTwitter } from 'react-icons/lu';
import { PiLinkedinLogoBold } from 'react-icons/pi';
import logo from '../../assets/img/logo-white-text.svg';
import iso from '../../assets/img/iso.png';
import decoration from '../../assets/img/decorations/deco-footer.svg';
import {Await, Link, useLoaderData} from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import Container from "../Container/Container";
import Heading from "../Heading/Heading";
import Button from "../Button/Button";
import Text from "../Text/Text";
import {Trans, useTranslation} from "react-i18next";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

const Footer = () => {
    const {data} = useLoaderData();
    const { t } = useTranslation();
    const {currentLangCode, langForInUrl} = useCurrentLangCode();

    return (
        <S.Footer>
            <Container>
                <S.FooterContact>
                    <S.Body>
                        <Heading white="true" tag="h2">{t('footer.banner_title')}</Heading>
                        <Text white="true">{t('footer.banner_text')}</Text>
                    </S.Body>
                    <Button primary="true" to={`${langForInUrl}/contact`}>{t('footer.banner_button')} <HiArrowSmRight/></Button>
                    <S.Decoration src={decoration} draggable="false" loading="lazy" alt="" />
                </S.FooterContact>
            </Container>

            <S.FooterContainer>
                <S.AboutYoumie>
                    <Link to={`${langForInUrl}/`} aria-label="home">
                        <S.Logo src={logo} alt="Logo van Youmie" loading="lazy" />
                    </Link>
                    <p>{t('footer.text')}</p>
                    <p><Trans i18nKey="footer.creator">Part of <a href={currentLangCode?.code === "en" ? "https://zoomagroup.nl/en" : "https://zoomagroup.nl/"} target="_blank" rel="noreferrer">Zooma Group</a></Trans></p>
                    <S.Badge href={currentLangCode?.code === "en" ? "https://zooma.nl/en/blog/zooma-certifies-to-iso-27001-at-once" : "https://zooma.nl/blog/zooma-certificeert-in-een-keer-op-iso-27001"} target="_blank" rel="noreferrer"><img src={iso} alt="" loading="lazy" /></S.Badge>
                </S.AboutYoumie>
                <S.Nav>
                    <h3>{t('footer.menu_label')}</h3>
                    <ul>
                        <li><Link to={`${langForInUrl}/`}>{t('navbar.home_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/functionaliteiten`}>{t('navbar.functionalities_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/blog`}>{t('navbar.blogs_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/prijzen`}>{t('navbar.pricing_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/over-youmie`}>{t('navbar.about_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/youmie-network`}>{t('navbar.network_label')}</Link></li>
                        <li><Link to={`${langForInUrl}/contact`}>{t('navbar.contact_label')}</Link></li>
                    </ul>
                </S.Nav>
                <S.Nav>
                    <h3>{t('footer.cases_label')}</h3>
                    <React.Suspense fallback={
                        <ul>
                            {Array.from({length: 6}, (_, index) => (
                                <li key={index}><Skeleton width="50%" /></li>
                            ))}
                        </ul>
                    }>
                        <Await resolve={data}>
                            {data => (
                                <ul>
                                    {data?.filter(item => Boolean(item?.inFooter))?.map(item => (
                                        item.slug
                                            ? <li key={item.id}><Link to={`${langForInUrl}/cases/${item.slug}`}>{item.title}</Link></li>
                                            : <li key={item.id} className="disabled"><Link to={`${langForInUrl}/cases`}>{item.title}</Link></li>
                                    ))}
                                    <li>
                                        <Link to={`${langForInUrl}/cases`}>{t('footer.allCases_label')}</Link>
                                    </li>
                                </ul>
                            )}
                        </Await>
                    </React.Suspense>
                </S.Nav>
                <S.Nav>
                    <h3>{t('footer.contact_label')}</h3>
                    <ul>
                        <li><a href="tel:+31712034012"><HiOutlinePhone /> +31 71 2034012</a></li>
                        <li><a href="mailto:info@youmie.nl"><HiOutlineMail /> info@youmie.nl</a></li>
                        <li><a href="https://www.google.com/maps/place/Dorus+Rijkersweg+15,+2315+WC+Leiden/data=!4m2!3m1!1s0x47c5c69d22ac9f61:0x8e014dc1d00a1e65?sa=X&ved=2ahUKEwiFxpPzsub1AhWHvaQKHe3DCesQ8gF6BAgYEAE" target="_blank" rel="noreferrer"><HiOutlineLocationMarker /> Dorus Rijkersweg 15<br/>2315WC, Leiden</a></li>
                    </ul>
                    <S.IconList>
                        <a href="https://www.instagram.com/youmie_apps/" target="_blank" rel="noreferrer"><FaInstagram /></a>
                        <a href="https://www.facebook.com/profile.php?id=100095338516629" target="_blank" rel="noreferrer"><RiFacebookCircleLine /></a>
                        <a href="https://www.linkedin.com/company/youmie/" target="_blank" rel="noreferrer"><PiLinkedinLogoBold /></a>
                        <a href="https://twitter.com/Youmie_apps" target="_blank" rel="noreferrer"><LuTwitter /></a>
                    </S.IconList>
                </S.Nav>
            </S.FooterContainer>

            <S.SubFooter>
                <S.StyledText><Link to={`${langForInUrl}/algemene-voorwaarden`}>{t('footer.termsAndConditions_label')}</Link></S.StyledText>
                <S.StyledText><Link to={`${langForInUrl}/privacyverklaring`}>{t('footer.privacyPolicy_label')}</Link></S.StyledText>
                <S.StyledText><Link to={`${langForInUrl}/cookiebeleid`}>{t('footer.cookies_label')}</Link></S.StyledText>
                <S.StyledText>{t('footer.copyright_label')} &copy; {new Date().getFullYear()} Youmie</S.StyledText>
            </S.SubFooter>
        </S.Footer>
    )
}

export default Footer
