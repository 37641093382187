import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {languagesData} from "./constants/languages";

const resources = {};
languagesData.forEach((language) => {
    resources[language.code?.toLowerCase()] = { translation: language.locale };
});

i18n.use(initReactI18next).init({
    debug: process.env.REACT_APP_ENVIRONMENT_TYPE !== 'test' && process.env.REACT_APP_ENVIRONMENT_TYPE !== 'production',
    fallbackLng: 'nl',
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    lowerCaseLng: true,
    resources: resources
});

export default i18n;