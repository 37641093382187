import React from 'react'
import * as S from './Feature.elements';
import Heading from "../Heading/Heading";
import Text from "../Text/Text";

const Feature = ({image, alt, heading, description}) => {
    return (
        <S.Item>
            <S.Img src={image} alt={alt || ""} />
            <Heading tag="h3">{heading}</Heading>
            <Text>{description}</Text>
        </S.Item>
    )
}

export default Feature
