import React from 'react'
import * as S from './FeatureGrid.elements';

// Assets
import squigglyLine from '../../assets/img/decorations/deco-squiggly-line-left-1.svg';
import ContainerSmall from "../ContainerSmall/ContainerSmall";
import Wrapper from "../Wrapper/Wrapper";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";
import Container from "../Container/Container";

const FeatureGrid = ({title, description, children}) => {
    
    return (
        <Wrapper>
            <S.Decoration src={squigglyLine} alt="" draggable="false" />
            <ContainerSmall as="header">
                <Heading tag="h2">{title}</Heading>
                <Text>{description}</Text>
            </ContainerSmall>
            <Container>
                <S.Grid> 
                    {children}
                </S.Grid>
            </Container>
        </Wrapper>
    )
}

export default FeatureGrid
