import React from 'react'
import * as S from './Atmospheric.elements';
import atmospheric from '../../assets/img/blocks/atmospheric/image.jpg';
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import {HiArrowSmRight} from "react-icons/hi";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

const Atmospheric = ({data}) => {
    const {langForInUrl} = useCurrentLangCode();

    return (
        <S.AtmosphericContainer>
            <S.Outer>
                <S.Inner $background={atmospheric}>
                    <S.Flex>
                        <S.Card>
                            <Heading tag="h4">{data[0]?.blogsTitle}</Heading>
                            <S.StyledText>{data[0]?.blogsText}</S.StyledText>
                            <Button primary="true" to={`${langForInUrl}/${data[0]?.blogsButtonLink}`}>{data[0]?.blogsButtonLabel} <HiArrowSmRight/></Button>
                        </S.Card>
                    </S.Flex>
                </S.Inner>
            </S.Outer>
        </S.AtmosphericContainer>
    )
}

export default Atmospheric
