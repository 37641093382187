import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: ${props => props.secondary ? 'var(--color-surface)' : 'var(--color-bg)'};
    ${props => props.$exclude !== 'top' && 'padding-top: 160px;'};
    ${props => props.$exclude !== 'bottom' && `padding-bottom: ${props.last ? '240px' : '160px'};`}
    position: relative;

    ${({$excludeArr}) => $excludeArr?.includes('top') && 'padding-top: 0;'};
    ${({$excludeArr, $last}) => $excludeArr?.includes('bottom') && `padding-bottom: ${$last ? '240px' : '0'};`};
    
    @media screen and (max-width: 768px) {
        ${props => props.$exclude !== 'top' && `padding-top: ${props.first ? '160px' : '80px'};`}
        ${props => props.$exclude !== 'bottom' && `padding-bottom: ${props.last ? '240px' : '80px'};`}

        ${({$excludeArr}) => $excludeArr?.includes('top') && 'padding-top: 0;'};
        ${({$excludeArr, $last}) => $excludeArr?.includes('bottom') && `padding-bottom: ${$last ? '240px' : '0'};`};
    }
`;

export default Wrapper;