import styled from 'styled-components';
import { PiGlobe } from 'react-icons/pi';
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

const StyledNavbarLanguage = styled.div`
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    font-family: var(--font-button);
    font-weight: var(--fw-bold);
    line-height: 1;
    border-radius: 24px;
    font-size: var(--fs-button);
    color: var(--color-text-on-bg);
    text-align: center;
    display: inline-flex;
    align-items: center;
    padding: 12px;
    margin: 0 0 0 8px;
    background-size: 0 100%;
    background-image: linear-gradient(to left, rgba(0,0,0,.02), rgba(0,0,0,.02));
    background-repeat: no-repeat;
    transition: transform .2s ease, background-color .2s ease, background-size .2s ease;
    
    &:active {
        transform: translateY(3px);
    }
    
    &:hover, &:focus {
        background-image: linear-gradient(to left, rgba(0,0,0,.02), rgba(0,0,0,.02));
        background-size: 100% 100%;
    }

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        margin: 2px 0 0 0;
        padding: 8px;
    }
`;

const StyledGlobe = styled(PiGlobe)`
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
    
    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        margin-top: -2px;
    }
`;

export const NavbarLanguage = () => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {currentLangCode} = useCurrentLangCode();
    const {i18n} = useTranslation();

    const handleLanguageSwitch = () => {
        let newLanguageCode = currentLangCode?.code === 'en' ? 'nl' : 'en';

        let newPathname;
        if(Boolean(currentLangCode)) {
            newPathname = newLanguageCode === "nl"
                ? pathname.replace(`/${currentLangCode.code}/`,  "/")
                : pathname.replace(`/${currentLangCode.code}/`, `/${newLanguageCode}/`)
            ;
        } else {
            newPathname = newLanguageCode === "nl" ? pathname : `/${newLanguageCode}${pathname}`;
        }

        i18n.changeLanguage(newLanguageCode);

        navigate(newPathname);
    }

    return (
        <StyledNavbarLanguage onClick={handleLanguageSwitch}>
            <StyledGlobe /> {currentLangCode?.code === 'en' ? 'NL' : 'EN'}
        </StyledNavbarLanguage>
    );
}