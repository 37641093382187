import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Functionalities = styled.div`
    margin-top: -300px;
    position: relative;
    z-index: 95;
`;

export const FeatureSwitchWrapper = styled.div`
    background-color: var(--color-white);
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.05);
    
    @media screen and (max-width: 992px) {
        box-shadow: none;
        background-color: transparent;
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px;
    background-color: var(--color-white);
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.05);
    
    @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

export const Item = styled(Link)`
    border-radius: 10px;
    padding: 16px;
    display: flex;
    transition: background-color .2s ease;

    &:hover {
        background-color: #FBFBFB;
    }
`;

export const Figure = styled.figure`
    flex-shrink: 0;
    margin-right: 16px;
`;

export const Img = styled.img`
    width: 100%;
    max-width: 40px;
    max-height: 40px;
`;

export const Spacing = styled.div`
    padding: ${({$last}) => $last ? '80px 0 200px 0' : '80px 0'};
    
    @media screen and (max-width: 768px) {
        padding: ${({$last}) => $last ? '40px 0 160px 0' : '40px 0'};
    }
`;