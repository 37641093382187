import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import {RichText} from "../Text/Text";

export const Wrapper = styled.div`
    background-color: var(--color-surface);
    position: relative;

    @media screen and (max-width: 768px) {
        padding-top: 80px;
        padding-bottom: 80px;
        overflow: hidden;
    }
`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
    margin: 0 -40px;

    @media screen and (max-width: 768px) {
        flex-direction: column; 
        margin: 0;
    }
`;

export const Box = styled.section`
    margin: 0 40px;
    flex: 0 1 50%;

    @media screen and (max-width: 768px) {
        margin: 0;
        margin-bottom: 80px;
    }
`;

export const PhoneBox = styled.div`
    max-height: 816px;
    overflow: hidden;
    margin: 0 40px;
    flex: 0 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media screen and (max-width: 768px) {
        transform: rotate(10deg);
        margin: 0;
        max-height: unset;
    }
`;

export const Decoration = styled.img`
    width: 100px;
    height: 50px;
    margin-bottom: 40px;
`;

export const ParallaxGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 12px;
    align-items: center;

    img {
        width: 100%;
    }
`;

export const ParallaxItem = styled(Parallax)`
    figure.figure--margin {
        margin-top: 12px;
    }
`;

export const StyledRichText = styled(RichText)`
    margin-bottom: 40px;
`;