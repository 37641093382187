import styled, { css } from "styled-components";
import Text from "../../components/Text/Text";
import {ReactComponent as Check} from '../../assets/img/pricing/check.svg';
import {Link} from "react-router-dom";

export const StyledPricingTable = styled.section``;

export const HeadingWrapper = styled.div`
    margin-bottom: 10px;
    padding-left: 48px;
    padding-right: 48px;

    @media screen and (max-width: 768px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media screen and (max-width: 425px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const Table = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 48px;
    padding-right: 48px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { display: none; }
    
    &:not(:first-of-type) {
        margin-bottom: 50px;
    }
    
    @media screen and (max-width: 1280px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const StickyTableWrapper = styled.div`
    transition: top .2s ease, box-shadow .2s ease;
    background-color: var(--color-bg);
    padding-top: 8px;
    padding-bottom: 8px;
    
    ${({$isTableOpen, $scrolled}) => $isTableOpen && css`
        position: sticky;
        top: ${$scrolled ? '85px' : '97px'};
        z-index: 2;

        &.is-pinned, &.is-pinned-mobile {
            box-shadow: 0 4px 10px 0 rgba(0,0,0,0.1);
        }
    `};

    @media screen and (min-width: 1441px) {
        // Some extra spacing due to chrome scrollbar bug: https://stackoverflow.com/questions/31391459/how-can-i-expand-a-child-div-to-100-screen-width-if-the-container-div-is-smalle
        width: calc(100vw - 20px);
        margin-left: calc(-50vw + 50% + 10px);
    }

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        top: 81px;
    }
`;

export const StickyTable = styled(Table)`
    max-width: var(--width-container);
    margin: 0 auto;
`;

export const CategoryRowWrapper = styled.div`
    border-bottom: 1px solid var(--color-surface-30);
    ${({$open}) => $open && `padding-bottom: 20px`};
    transition: background-color .2s ease;
    -webkit-tap-highlight-color: transparent;
    width: 100%;

    @media screen and (max-width: calc(${({$cells}) => $cells} * 300px)) {
        width: calc(${({$cells}) => $cells} * 300px); // N cells * cell width
    }

    @media screen and (max-width: 768px) {
        width: calc(${({$cells}) => $cells} * 220px); // N cells * cell width
    }

    @media screen and (max-width: 525px) {
        width: calc(${({$cells}) => $cells} * 150px); // N cells * cell width
    }
`;

export const CategoryRow = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 64px;
    cursor: pointer;

    // Add invisible padding left to category row, to align columns of visible indentend subrows (indent is overwritten with transform in a Cell)
    @media screen and (min-width: 1281px) {
        ${({$indent}) => $indent && 'padding-left: 18px;'};
    }
`;

export const ExpandedContent = styled.div`
    background-color: var(--color-background);
    overflow: hidden;
    opacity: ${({$open}) => $open ? '1' : '0'};
    transition: max-height 0.2s ease, opacity .2s ease;
    padding-left: 18px;
    width: 100%;
    ${({$height}) => $height && `max-height: ${$height}`};
    
    @media screen and (max-width: 1280px) {
        padding-left: 0;
    }
`;

export const Category = styled.h4`
    font-family: var(--font-heading);
    color: var(--color-heading-on-bg);
    line-height: 1.25;
    font-size: 1.8rem;
    font-weight: var(--fw-bold);
    margin: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    
    svg {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        transition: transform .2s ease;
        ${({$open}) => $open && 'transform: rotate(90deg);'};
    }
    
    @media screen and (max-width: 768px) {
        gap: 4px;
        
        svg {
            width: 20px;
            height: 20px;
        }
    } 
    
    @media screen and (max-width: 525px) {
        font-size: 1.4rem;
    }
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    border-radius: 14px;
    ${({$isOdd}) => $isOdd && css`
        background-color: var(--color-surface-30);
    `};
    
    @media screen and (max-width: 1280px) {
        border-radius: 0;
    }
    
    @media screen and (max-width: calc(${({$cells}) => $cells} * 300px)) {
        width: calc(${({$cells}) => $cells} * 300px); // N cells * cell width
    }

    @media screen and (max-width: 768px) {
        width: calc(${({$cells}) => $cells} * 220px); // N cells * cell width
    }

    @media screen and (max-width: 525px) {
        width: calc(${({$cells}) => $cells} * 150px); // N cells * cell width
    }

    // Add invisible padding left to first row, to align columns of visible indentend subrows (indent is overwritten with transform in a Cell)
    @media screen and (min-width: 1281px) {
        ${({$indent}) => $indent && 'padding-left: 18px;'};
    }
`;

export const Cell = styled.div`
    padding: ${({$hasSubRows}) => $hasSubRows ? '12px 20px 12px 0' : '12px 20px'};
    margin: auto 0;
    ${({$rowTitle}) => !$rowTitle && 'text-align: center'};
    flex: 1 1 300px;
    border-radius: 14px;
    background-color: ${({$isOdd}) => $isOdd ? 'var(--color-surface-30)' : 'var(--color-bg)'};
    transition: background-color .2s ease;
    ${({$rowTitle}) => $rowTitle && 'z-index: 1;'};

    &:first-of-type {
        position: sticky;
        left: 0;
    }

    @media screen and (max-width: 1280px) {
        &:first-of-type {
            padding-left: ${({$hasSubRows}) => $hasSubRows ? '12px' : '48px'};
        }
    }
    
    @media screen and (max-width: 768px) {
        flex: 1 1 220px;

        &:first-of-type {
            padding-left: ${({$hasSubRows}) => $hasSubRows ? '4px' : '24px'};
        }
    }
    
    @media screen and (max-width: 525px) {
        padding: 12px 10px;
        flex: 1 1 180px;

        h3 {
            font-size: 1.8rem;
        }
    }

    @media screen and (max-width: 425px) {
        padding: 12px 4px;

        &:first-of-type {
            padding-left: ${({$hasSubRows}) => $hasSubRows ? '4px' : '16px'};
        }
    }
    
    // Transform back to original position to remove visible indent (padding-left is set on first Row)
    @media screen and (min-width: 1281px) {
        ${({$indent}) => $indent && 'transform: translateX(-18px);'};
    }
`;

export const StyledLink = styled(Link)`
    font-family: var(--font-heading);
    color: var(--color-heading-on-bg);
    line-height: 1.25;
    font-size: var(--fs-h4);
    font-weight: var(--fw-bold);

    svg {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        transition: transform .2s ease;
    }
    
    @media screen and (max-width: 525px) {
        font-size: 1.4rem;
    }
`;

export const Subtitle  = styled(Text)`
    font-size: 1.2rem;
`;

export const CellText = styled(Text)`
    white-space: pre-wrap;
    font-size: 1.4rem;
`;

export const StyledCheck = styled(Check)`
    vertical-align: middle;
    opacity: ${({$hide}) => $hide ? 0 : 1};
    transition: .2s ease opacity;
    
    @media screen and (max-width: 525px) {
        width: 24px;
        height: 24px;
    }
`;

export const IconBox = styled.span`
    display: inline;
    white-space: nowrap;
`;