import React, { useRef } from 'react'
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import * as S from './TestimonialSlider.elements';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Wrapper from "../Wrapper/Wrapper";
import Container from "../Container/Container";
import Heading from "../Heading/Heading";
import {RichText} from "../Text/Text";
import Testimonial from "../Testimonial/Testimonial";
import {Await} from "react-router-dom";
import {parseHTMLText} from "../../helpers/parseHTMLText";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";


const TestimonialSlider = ({data}) => {
    const {langForInUrl} = useCurrentLangCode();

    const slider = useRef(null);

    const settings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    };

    return (
        <>
            <Wrapper $exclude="bottom">
                <Container>
                    <React.Suspense>
                        <Await resolve={data}>
                            {(data) => (
                                <S.Header>
                                    <S.Box>
                                        <Heading tag="h2">{data[0]?.title}</Heading>
                                        <RichText dangerouslySetInnerHTML={{__html: parseHTMLText(data[0]?.text, langForInUrl)}} />
                                    </S.Box>
                                    <S.Buttons>
                                        <S.Button onClick={() => slider?.current?.slickPrev()}>
                                            <HiOutlineArrowNarrowLeft />
                                        </S.Button>
                                        <S.Button onClick={() => slider?.current?.slickNext()}>
                                            <HiOutlineArrowNarrowRight />
                                        </S.Button>
                                    </S.Buttons>
                                </S.Header>
                            )}
                        </Await>
                    </React.Suspense>
                </Container>
            </Wrapper>
            <S.StyledWrapper last="true" $exclude="top">
                <Container>
                    <S.SliderWrapper>
                        <React.Suspense fallback={
                            <S.StyledSlider ref={slider} {...settings}>
                                {Array.from({length: 7}, (_, index) => (
                                    <Testimonial key={index} loading="true"/>
                                ))}
                            </S.StyledSlider>
                        }>
                            <Await resolve={data}>
                                {(data) => (
                                    <S.StyledSlider ref={slider} {...settings}>
                                        {data[0]?.testimonials?.map(item => (
                                            <Testimonial
                                                key={item.id}
                                                image={item.imageUrl}
                                                userName={item.userName}
                                                role={item.jobTitle}
                                                text={item.text}
                                                buttonLabel={item.buttonLabel}
                                                buttonLink={item.buttonLink}
                                            />
                                        ))}
                                    </S.StyledSlider>
                                )}
                            </Await>
                        </React.Suspense>
                    </S.SliderWrapper>
                </Container>
            </S.StyledWrapper>
        </>
    );
}

export default TestimonialSlider
