import {useEffect, useState} from "react";

export const useChangeNavbarOnScroll = () => {
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        let scrollPosition = window.scrollY;
        const listenScroll = () => {
            (window.scrollY >= scrollPosition && window.scrollY > 85) ? setScrolled(true) : setScrolled(false);
            scrollPosition = window.scrollY;
        }

        window.addEventListener('scroll', listenScroll);
        return () => window.removeEventListener('scroll', listenScroll);
    }, []);

    return { scrolled };
};