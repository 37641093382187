import React from 'react'
import * as S from './TextLine.elements';
import ContainerMedium from "../ContainerMedium/ContainerMedium";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";

const TextLine = ({heading, text}) => {

    return (
        <S.Wrapper>
            <ContainerMedium>
                <S.Flex>
                    <S.Box>
                        <Heading tag="h2">{heading}</Heading>
                    </S.Box>
                    <Text>{text}</Text>
                </S.Flex>
            </ContainerMedium>
        </S.Wrapper>
    )
}

export default TextLine
