import styled from "styled-components";
import Container from "../../components/Container/Container";

export const StyledContainer = styled(Container)`
    max-width: 900px;
`;

export const Text = styled.p`
    color: var(--color-text-on-bg);
    font-size: var(--font-size-default);
    line-height: 1.5;
    margin: 8px 0;
`;

export const List = styled.ul`
    margin: 16px 0;
    list-style-type: disc;
`;

export const Item = styled.li`
    color: var(--color-text-on-bg);
    font-size: var(--font-size-default);
    line-height: 1.5;
    margin: 0 0 0 25px;
`;

export const Url = styled.a`
    color: var(--color-text-on-bg);
    font-size: var(--font-size-default);
    line-height: 1.5;
    margin: 8px 0;
    font-weight: 600;
    background: linear-gradient(to bottom, #D4E7D5 0%, #D4E7D5 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 4px 50%;
    text-decoration: none;
    transition: background-size .2s;

    :hover {
        background-size: 4px 100%;
    }
`;

export const Section = styled.section`
    margin: 40px 0;
`;

export const TableWrapper = styled.div`
    color: var(--color-text-on-bg);
    font-size: var(--font-size-default);
    line-height: 1.5;
    overflow-x: auto;
    margin: 16px 0;

    table {
        width: 100%;
        min-width: 768px;
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px solid #000;
    }
    
    th {
        border: 1px solid #000;
        padding: 10px;
        text-align: left;
        font-weight: var(--fw-bold);
    }
    
    td {
        border: 1px solid #000;
        padding: 10px;
    }
`;