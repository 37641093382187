import styled from 'styled-components';
import Text from '../../components/Text/Text';

export const InfoBannerContainer = styled.div`
    position: relative;
    margin-top: -160px;
`;

export const Primary = styled.section`
    border-radius: 12px;
    background-color: var(--color-info-banner-primary);
    display: flex;
    /* width: 100% - 64px(padding left and right of Secondary) - btnWidth */
    width: ${(props) => `calc(100% - ${props.btnWidth}px - 64px)`};

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        width: calc(100% - 32px);
    }
`;

export const Secondary = styled.div`
    position: relative;
    z-index: -1;
    display: flex;
    justify-content: flex-end;
    margin-top: -80px;
    border-radius: 12px;
    background-color: var(--color-info-banner-secondary);
    padding: 32px;
    width: 60%;
    margin-left: auto;

    @media screen and (max-width: 1024px) {
        padding-top: 64px;
        margin-top: -40px;
        width: calc(100% - 32px);
    }
`;

export const Avatars = styled.div`
    position: relative;
    flex: 0 0 170px;
    width: 170px;
    height: 180px;
    margin: 24px 0 24px 24px;

    @media screen and (max-width: 768px) {
        width: 135px;
        flex: 0 0 150px;
        height: 160px;
        margin: 24px 0 0 24px;
    }
`;

export const Avatar = styled.img`
    position: absolute;
    width: 100px;
    height: 100px;
    border: 6px solid var(--color-white);
    border-radius: 50%;
    transition: 0.2s transform ease;

    &.avatar--one {
        top: 50px;
        left: 0;
    }

    &.avatar--two {
        top: 0;
        right: 0;
    }

    &.avatar--three {
        top: 80px;
        right: 0;
    }

    &:hover {
        transform: scale(1.05);
    }

    @media screen and (max-width: 768px) {
        width: 80px;
        height: 80px;
        border: 5px solid var(--color-white);

        &.avatar--one {
            top: 35px;
            left: 0;
        }

        &.avatar--two {
            top: 0;
            right: 0;
        }

        &.avatar--three {
            top: 60px;
            right: 0;
        }
    }
`;

export const Body = styled.div`
    padding: 32px 24px;

    @media screen and (max-width: 1024px) {
        padding: 0 24px 24px 24px;
    }
`;

export const StyledText = styled(Text)`
    margin-bottom: 0;
`;