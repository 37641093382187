import {Helmet} from "react-helmet-async";
import {languagesData} from "../../constants/languages";
import React from "react";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

export const Seo = ({title, description, slug, image}) => {
    const {currentLangCode} = useCurrentLangCode();
    title = title?.replaceAll("@", ""); // Remove @ (used for word breaks) from title.

    return (
        <Helmet>
            <title>Youmie &mdash; {title}</title>
            <meta property="og:title" content={`Youmie - ${title}`} />
            <meta name="twitter:title" content={`Youmie - ${title}`} />
            {description && <meta name="description" content={description} />}
            {image && <meta property="og:image" content={image} />}
            {description && <meta property="og:description" content={description} />}
            {description && <meta name="twitter:description" content={description} />}
            {image && <meta name="twitter:image" content={image} />}
            {slug &&
                <>
                    <link rel="canonical" href={`https://youmie.nl${(currentLangCode && currentLangCode?.code !== "nl") ? `/${currentLangCode.code}` : ""}${slug}`} />
                    {languagesData.map(item => (
                        <link key={item.code} rel="alternate" href={`https://youmie.nl${item.code !== "nl" ? `/${item.code}` : ""}${slug}`} hrefLang={item.code} />
                    ))}
                    <link rel="alternate" href={`https://youmie.nl${slug}`} hrefLang="x-default" />
                </>
            }
        </Helmet>
    )
}