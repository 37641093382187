import styled from 'styled-components';

export const Item = styled.article`
    /* display: flex;
    flex-direction: column;
    align-items: center; */
`;

export const Img = styled.img`
    width: 32px;
    height: 32px;
    margin-bottom: 12px;
`;