import styled from 'styled-components';
import Container from "../../components/Container/Container";
import Slider from "react-slick";

export const StyledAtmosphericHero = styled.section`
    position: relative;
    background: linear-gradient(0deg, rgba(0,0,0,0.04) 0%, rgba(0,0,0,0) 100%);
    overflow: hidden;
    margin-bottom: 40px;

    svg {
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 95;
        width: 100%;
        margin-bottom: -4px;
        
        @media screen and (min-width: 1921px) {
            height: 154px;
        }
    }
`;

export const Outer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 12 / 5;
    background-color: var(--color-background);
    
    @supports not (aspect-ratio: 12 / 5) {
        overflow: hidden;
        padding-top: 41.67%;
    }
`;

export const Inner = styled.div`
    position: relative;
    height: 100%;
    min-height: 700px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding-top: 102px;
        align-items: flex-start;
    }
`;

export const Flex = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    pointer-events: none;
`;

export const Card = styled.article`
    pointer-events: initial;
    background-color: var(--color-white);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 460px;
    
    @media screen and (max-width: 768px) {
        max-width: unset;
    }
`;

export const StyledSlider = styled(Slider)`
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 14px;
    
    div {
        height: 100%;
    }
    
    .slick-dots {
        bottom: 30px;
        left: 50%;
        width: unset;
        transform: translateX(-50%);

        li {
            display: inline-block;
            transition: all .2s;
            width: 10px;
            height: 10px;

            button {
                transition: all .2s;
                background-color: var(--color-white);
                border-radius: 5px;
                width: 10px;
                height: 10px;

                &:before {
                    content: none;
                }
            }

            &.slick-active {
                transition: all .2s;
                width: 30px;

                button {
                    background-color: var(--color-primary);
                    width: 30px;
                }
            }
        }
        
        // Because rounded svg is fluid, dots need to be in sight
        @media screen and (min-width: 526px) { bottom: 40px; }
        @media screen and (min-width: 769px) { bottom: 50px; }
        @media screen and (min-width: 993px) { bottom: 50px; }
        @media screen and (min-width: 1025px) { bottom: 60px; }
        @media screen and (min-width: 1281px) { bottom: 70px; }
        @media screen and (min-width: 1441px) { bottom: 80px; }
        @media screen and (min-width: 1681px) { bottom: 90px; }
    }
    
    .slick-slide {
        border: none;
        overflow: hidden;
    }
    
    div {
        outline: none;
    }
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;