import React from 'react'
import * as S from './AccordionList.elements';
import faq from '../../assets/img/pricing/faq.png';
import {parseHTMLText} from "../../helpers/parseHTMLText";
import {RichText} from "../Text/Text";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import Heading from "../Heading/Heading";

const AccordionList = ({data, children}) => {
    const {langForInUrl} = useCurrentLangCode();

    return (
        <S.Flex>
            <S.Box>
                <Heading tag="h2">{data?.questionTitle}</Heading>
                <RichText dangerouslySetInnerHTML={{__html: parseHTMLText(data?.questionText, langForInUrl)}} />
                <S.Accordions>
                    {children}
                </S.Accordions>
            </S.Box>
            <S.Box>
                <S.ImageWrapper>
                    <img src={faq} alt='' />
                </S.ImageWrapper>
            </S.Box>
        </S.Flex>
    )
}

export default AccordionList
