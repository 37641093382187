import React from 'react'
import { HiArrowSmRight } from 'react-icons/hi';
import * as S from './CustomerSlider.elements';
import uvsv1 from '../../assets/img/contact/slider1.jpg';
import uvsv2 from '../../assets/img/contact/slider2.jpg';
import uvsv3 from '../../assets/img/contact/slider3.jpg';
import sliderDecoration from '../../assets/img/decorations/deco-slider.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Container from "../Container/Container";
import Wrapper from "../Wrapper/Wrapper";
import Button from "../Button/Button";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";

const CustomerSlider = ({data}) => {
    const {langForInUrl} = useCurrentLangCode();

    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        // vertical: true,
        // verticalSwiping: true
    };

    return (
        <Wrapper $exclude="bottom">
            <Container>
                <S.Flex>
                    <S.StyledSlider {...settings}>
                        <S.Slide>
                            <img src={uvsv1} alt="" />
                        </S.Slide>
                        <S.Slide>
                            <img src={uvsv2} alt="" />
                        </S.Slide>
                        <S.Slide>
                            <img src={uvsv3} alt="" />
                        </S.Slide>
                    </S.StyledSlider>
                    <S.Content>
                        <div>
                            <Heading tag="h2">{data[0]?.sliderTitle}</Heading>
                            <Text>{data[0]?.sliderText}</Text>
                            <Button tertiary="true" to={`${langForInUrl}/${data[0]?.sliderButtonLink}`}>{data[0]?.sliderButtonLabel} <HiArrowSmRight/></Button>
                        </div>
                        <S.Decoration src={sliderDecoration} draggable="false" loading="lazy" alt="" />
                    </S.Content>
                </S.Flex>
            </Container>
        </Wrapper>
    )
}

export default CustomerSlider
