import styled from 'styled-components';
import Text from '../Text/Text';

export const Label = styled.label`
    color: var(--color-heading-on-bg);
    font-weight: var(--fw-bold);
    font-size: var(--font-size-default);
    line-height: 1.25;
`;

export const Input = styled.input`
    margin-top: 5px;
    margin-bottom: 16px;
    display: block;
    width: 100%;
    resize: ${props => props.textarea ? 'vertical' : ''};
    border: 1px var(--color-border) solid;
    padding: 12px 16px;
    font-size: var(--font-size-default);
    line-height: 1.25;
    border-radius: 5px;
    outline: none;
    transition: border .2s ease, box-shadow .2s ease;
    /* box-shadow: 0 2px 4px 0 rgba(0,0,0,0.02); */

    &::placeholder {
        color: var(--color-placeholder);
    }

    &:hover {
        border: 1px var(--color-border-hover) solid;
    }

    &:focus {
        border: 1px var(--color-primary) solid;
        box-shadow: 0 0 0 2px #D2EFDD;
    }
`;

export const Error = styled(Text)`
    font-size: var(--font-size-form);
    color: var(--color-error);
    margin-bottom: 20px;
`;