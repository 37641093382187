import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import {defer, Outlet, useLoaderData, useParams} from 'react-router-dom';
import Hero from "../../components/Hero/Hero";
import Container from "../../components/Container/Container";
import Wrapper from "../../components/Wrapper/Wrapper";
import AccordionList from "../../components/AccordionList/AccordionList";
import Accordion from "../../components/Accordion/Accordion";
import {Seo} from "../../components/Seo/Seo";
import {useTranslation} from "react-i18next";
import {PricingPackages} from "../../components/PricingPackages/PricingPackages";
import MarketingPopup from "../../components/MarketingPopup/MarketingPopup";
import {DeferredBulletSection} from "../../components/DeferredBulletSection/DeferredBulletSection";

export async function pricingLoader({request, params}) {
    const lang = params?.lang;
    const hasPricingPageType = Boolean(params?.pricingPageType);

    const bulletSectionData = fetch(`${process.env.REACT_APP_API_URL}/bullet-section${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const pricingEndpoint = hasPricingPageType ? `pricing-page/${params.pricingPageType}` : 'pricing-page';
    let pricingPageData = await fetch(`${process.env.REACT_APP_API_URL}/${pricingEndpoint}/${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    if(!hasPricingPageType) {
        pricingPageData = pricingPageData[0];
    }

    return defer({bulletSectionData, pricingPageData});
}

const Pricing = () => {
    const {t} = useTranslation();
    const {bulletSectionData, pricingPageData} = useLoaderData();
    const {slug} = useParams();

    return (
        <>
            {!Boolean(slug) &&
                <Seo
                    title={t('navbar.pricing_label')}
                    description={pricingPageData?.metaDescription}
                    slug={`/prijzen/${pricingPageData?.slug}`}
                />
            }

            <Hero
                type="overlap"
                heading={pricingPageData?.pageTitle}
                text={pricingPageData?.text}
                supportWordBreak
            />

            <PricingPackages key={pricingPageData?.id} data={pricingPageData} />

            <DeferredBulletSection data={bulletSectionData} />

            <Wrapper last="true">
                <Container>
                    <AccordionList data={pricingPageData}>
                        {(pricingPageData?.questionOneTitle &&  pricingPageData?.questionOneText) &&
                            <Accordion
                                title={pricingPageData?.questionOneTitle}
                                content={pricingPageData?.questionOneText}
                            />
                        }
                        {(pricingPageData?.questionTwoTitle && pricingPageData?.questionTwoText) &&
                            <Accordion
                                title={pricingPageData?.questionTwoTitle}
                                content={pricingPageData?.questionTwoText}
                            />
                        }
                        {(pricingPageData?.questionThreeTitle && pricingPageData?.questionThreeText) &&
                            <Accordion
                                title={pricingPageData?.questionThreeTitle}
                                content={pricingPageData?.questionThreeText}
                            />
                        }
                        {(pricingPageData?.questionFourTitle && pricingPageData?.questionFourText) &&
                            <Accordion
                                title={pricingPageData?.questionFourTitle}
                                content={pricingPageData?.questionFourText}
                            />
                        }
                    </AccordionList>
                </Container>
            </Wrapper>

            <MarketingPopup data={pricingPageData} />

            <Outlet />
        </>
    );
}

export default Pricing
