import styled from 'styled-components';
import Slider from 'react-slick';
import Wrapper from "../Wrapper/Wrapper";

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
`;

export const Box = styled.section`
    margin-right: 20px;

    @media screen and (min-width: 993px) {
        width: 50%;
    }
`;

export const Buttons = styled.section`
    flex-shrink: 0;
    margin: 0 -5px;
`;

export const Button = styled.button`
    border: 2px solid var(--color-primary);
    border-radius: 50%;
    width: 65px;
    height: 65px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color .2s ease;

    @media screen and (max-width: 500px) {
        display: block;
        width: 50px;
        height: 50px;
        margin-bottom: 4px;
    }
    
    svg {
        color: var(--color-primary);
        width: 24px;
        height: 24px;
        margin: auto;
        display: block;
    }
    
    &:hover {
        background-color: var(--color-primary);

        svg {
            color: var(--color-white);
        }
    }
`;

export const SliderWrapper = styled.div`
    margin-left: -8px;
`;

export const StyledWrapper = styled(Wrapper)`
    overflow: hidden;
    
    &:before, &:after {
        content: "";
        position: absolute;
        top: 0;
        width: 160px;
        height: 100%;
        pointer-events: none;
    }
    
    &:before {
        left: 0;
        background: linear-gradient(90deg, rgba(254, 254, 254, 1) 0%, rgba(254, 254, 254, 0) 100%);
        z-index: 2;
    }
    
    &:after {
        right: 0;
        background: linear-gradient(90deg, rgba(254, 254, 254, 0) 0%, rgba(254, 254, 254, 1) 100%);
        z-index: 1;
    }
    
    @media screen and (max-width: 1680px) {
        &:before, &:after {
            width: 48px;
        }
    }

    @media screen and (max-width: 768px) {
        &:before, &:after {
            width: 24px;
        }
    }

    @media screen and (max-width: 425px) {
        &:before, &:after {
            width: 16px;
        }
    }
`;

export const StyledSlider = styled(Slider)`
    position: initial;
    display: block;

    .slick-list {
        overflow: visible;
    }

    /* the slides */
    .slick-slide {
        padding: 16px 8px;
        height: inherit;

        > div {
            height: 100%;
        }
    }

    .slick-track {
        display: flex;
    }
`;