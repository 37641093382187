import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import rings from '../../assets/img/blocks/integrationParallax/rings.svg';

export const Wrapper = styled.div`
    background-color: var(--color-surface);
    position: relative;

    @media screen and (max-width: 1024px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
`;

export const Flex = styled.section`
    display: flex;
    align-items: center;

    margin: 0 -40px;
    
    @media screen and (max-width: 1024px) {
        margin: -40px 0;
        flex-direction: column-reverse;
        text-align: center;
    }
`;

export const Box = styled.div`
    flex: 0 1 40%;
    margin: 0 40px;

    @media screen and (max-width: 1270px) {
        flex: 0 1 50%;
    }

    @media screen and (max-width: 1024px) {
        margin: 40px 0;
        width: 100%;
        max-width: 500px;
    }
`;

export const TextBox = styled.article`
    flex: 0 1 60%;
    margin: 0 40px;

    @media screen and (max-width: 1270px) {
        flex: 0 1 50%;
    }

    @media screen and (max-width: 1024px) {
        margin: 40px 0;
        width: 100%;
    }

    a {
        margin-top: 20px;
    }
`;

export const IntegrationCloud = styled.section`
    background: url(${rings});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    overflow: hidden;
    
    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`;

export const Logos = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }

    @media screen and (max-width: 1024px) {
        &:before, &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 150px;
            height: 100%;
            pointer-events: none;
            z-index: 1;
        }

        &:before {
            left: 0;
            background: linear-gradient(270deg, rgba(212, 231, 213, 0) 0%, rgba(212, 231, 213, 1) 100%);
        }

        &:after {
            right: 0;
            background: linear-gradient(90deg, rgba(212, 231, 213, 0) 0%, rgba(212, 231, 213, 1) 100%);
        }
    }

    @media screen and (max-width: 768px) {
        &:before, &:after {
            width: 100px;
        }
    }
`;

export const ParallaxItem = styled(Parallax)`

    &.parallax--margin {
        margin: 0 20px;
    }

    @media screen and (max-width: 1024px) {
        display: flex;

        &.parallax--margin {
            transform: translateX(40px);
        }
    }

`;

export const Figure = styled.figure`
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 20px;

    @media screen and (max-width: 1270px) {
        margin-right: 20px;
    }
    
    @media screen and (max-width: 425px) {
        width: 80px;
        height: 80px;
        margin-bottom: 15px;
        margin-right: 15px;
    }

    span {
        width: 100%;
        height: 100%;
    }
`;

export const Logo = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--color-white);
`;