import {useLoaderData} from "react-router-dom";
import React from "react";
import {Paragraphs} from "../../components/Paragraphs/Paragraphs";
import TextHero from "../../components/TextHero/TextHero";
import {Seo} from "../../components/Seo/Seo";
import Loading from "../../components/Loading/Loading";

export async function policyLoader({request, params}) {
    const lang = params?.lang;
    const url = new URL(request.url);

    let webPolicyPathname = params.lang ? url.pathname.replace(`/${params.lang}/`, "") : url.pathname.replace("/", "");

    let endpoint;
    if(params?.slug) {
        endpoint = `apppolicies/${params.slug}`;
    } else {
        endpoint = `webpolicies/${webPolicyPathname}`;
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    });

    if(!res.ok) {
        throw new Response("Page is not found", { status: 404 });
    }

    const data = await res.json();

    if(data?.pdfNL && lang !== "en") {
        window.location.replace(data?.pdfNL);
        return {data: data.pdfNL};
    }

    if(data?.pdfEN && lang === "en") {
        window.location.replace(data?.pdfEN);
        return {data: data.pdfEN};
    }

    if(data?.externalLink) {
        window.location.replace(data?.externalLink);
        return {data: data.externalLink};
    }

    return {data};
}

const Policy = () => {
    const {data} = useLoaderData();

    return (
        <>
            {data?.title ? (
                <>
                    <Seo title={data.title} slug={`/${data.slug}`} />

                    <TextHero
                        title={data.title}
                        text={data?.text}
                    />

                    <Paragraphs data={data?.paragraphs} />
                </>
            ) : (
                <>
                    <Loading>Redirecting</Loading>
                </>
            )}
        </>
    );
}

export default Policy
