import styled, {css} from 'styled-components';
import Text from "../Text/Text";
import ContainerMedium from "../ContainerMedium/ContainerMedium";

export const StyledContainerMedium = styled(ContainerMedium)`
    ${({$paddingBottom}) => $paddingBottom && css`
        padding-bottom: 160px;

        @media screen and (max-width: 768px) {
            padding-bottom: 80px;
        }
    `}

    ${({$paddingTop}) => $paddingTop && css`
        padding-top: 160px;

        @media screen and (max-width: 768px) {
            padding-top: 80px;
        }
    `}

    @media screen and (max-width: 525px) {
        padding: 0;
    }
`;

export const Inner = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background-color: #134E4A;
    
    @media screen and (max-width: 525px) {
        border-radius: 0;
    }
`;

export const Content = styled.article`
    position: relative;
    padding: 50px;
    z-index: 1;

    @media screen and (max-width: 768px) {
        padding: 40px 24px;
    }
    
    @media screen and (max-width: 425px) {
        padding: 32px 16px;
    }
`;

export const StyledText = styled.div`
    font-family: var(--font-heading);
    color: var(--color-white);
    line-height: 1.4;
    margin: 8px 0;
    font-size: var(--fs-h3);
    font-weight: var(--fw-bold);
    max-width: 768px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        font-size: 2.2rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 2rem;
    }

    @media screen and (max-width: 525px) {
        font-size: 1.8rem;
    }

    a {
        color: var(--color-white);
        background: linear-gradient(to bottom, #1c6561 0%, #1c6561 100%);
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 4px 50%;
        text-decoration: none;
        transition: background-size .2s;

        :hover {
            background-size: 4px 100%;
        }
    }
`;

export const Person = styled(Text)`
    margin-top: 40px;
    
    @media screen and (max-width: 525px) {
        margin-top: 20px;
    }
`;

export const Decoration = styled.img`
    width: 100px;
    height: 50px;
    margin-bottom: 20px;
`;

export const DecorationCircle = styled.img`
    position: absolute;
    bottom: 0;
    right: 50px;

    @media screen and (max-width: 1024px) {
        right: -150px;
    }
`;