import styled from 'styled-components';

export const SegmentedControlWrapper = styled.div`
    margin-top: 24px;
`;

export const Grid = styled.section`
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 48px;
    padding-bottom: 50px;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 525px) {
        grid-template-columns: 1fr;
    }
`;