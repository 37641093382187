import styled, { css } from 'styled-components';
import Container from "../Container/Container";
import Button from "../Button/Button";

export const TextImageContainer = styled(Container)`
    padding-top: 80px;
    padding-bottom: ${props => props.$last ? '240px' : '80px'};

    @media screen and (max-width: 768px) {
        padding-bottom: ${props => props.$last ? '240px' : '80px'};
    }
`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'}; 
    margin: 0 -40px;

    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        margin: -40px 0;
    }
`;

export const Content = styled.article`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 40px;

    @media screen and (max-width: 1024px) {
        margin: 20px 0;
    }
`;

export const Figure = styled.figure`
    flex: 1;
    margin: 0 40px;

    @media screen and (max-width: 1024px) {
        margin: 20px 0;
    }
`;

export const Img = styled.img`
    /* padding-right: 80px; */
    width: 100%;
    ${props => props.$radius &&
        css`border-radius: 16px;`
    };
`;

export const Text = styled.article`

    h2 {
        font-family: 'Sora', sans-serif;
        margin: 8px 0;
        color: #171717;
        line-height: 1.25;
        font-size: 3rem;
        font-weight: 700;
        transition: font-size .2s ease;
    }

    h3 {
        font-family: 'Sora', sans-serif;
        margin: 8px 0;
        color: #171717;
        line-height: 1.25;
        font-size: 2.2rem;
        font-weight: 700;
        transition: font-size .2s ease;
    }

    p, a, li {
        color: var(--color-text-on-bg);
        font-size: var(--font-size-default);
        line-height: 1.5;
        margin: 8px 0;
    }

    a {
        font-weight: 600;
        background: linear-gradient(to bottom, #D4E7D5 0%, #D4E7D5 100%);
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 4px 50%;
        text-decoration: none;
        transition: background-size .2s;

        :hover {
            background-size: 4px 100%;
        }
    }

    ul {
        margin: 16px 0;
        /* columns: 2;
        column-gap: 60px; */
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        @media screen and (max-width: 425px) {
            grid-template-columns: 1fr;
            /* columns: 1; */
        }
    }

    li {
        margin: 0;

        &:before {
            content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z' stroke='%2304575D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7 10L9 12L13 8' stroke='%234BC17A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            /* width: 20px;
            height: 20px; */
            display: inline-block;
            vertical-align: sub;
            /* vertical-align: -webkit-baseline-middle; */
            margin-right: 15px;
        }
    }
`;

export const Icon = styled.img`
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
`;

export const StyledButton = styled(Button)`
    align-self: flex-start;
    margin-top: 8px;
`;