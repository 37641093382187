import styled from 'styled-components';
import { motion } from "framer-motion"; 

export const Grid = styled.div`
    display: grid;
    grid-template-areas: 
    '. center .' 
    'func0 center func1' 
    'func2 center func3' 
    'func4 center func5' 
    'func6 center func7' 
    '. center .';
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 60px repeat(4, auto) 60px;
    grid-gap: 10px;
    padding-top: ${props => props.$expanded ? '20px' : '160px'};
    padding-bottom: ${props => props.$expanded ? '40px' : '160px'};

    @media screen and (max-width: 1280px) {
        grid-template-rows: 0 repeat(4, auto) 0;
        padding-bottom: ${props => props.$expanded ? '0' : '160px'};
    }
    
    @media screen and (max-width: 850px) {
        display: block;
    }
`;

export const Phone = styled.div`
    grid-area: center;
    z-index: 2;
    display: flex;
    align-items: center;

    img {
        width: auto;
        object-fit: contain;
        height: 100%;
        max-height: 700px;
        border-radius: 20px;
        display: block;
        margin: 0 auto;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        border: 8px solid var(--color-white);

        @media screen and (max-width: 1280px) {
            max-height: 600px;
        }

        @media screen and (max-width: 992px) {
            height: 525px;
            margin: 0 auto;
        }
    }
`; 

export const Item = styled.div`
    grid-area: func${({$index}) => $index};
    border-radius: 10px;
    padding: 16px;
    display: flex;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 992px) {
        padding: 10px;
    }
`;

export const Figure = styled.figure`
    flex-shrink: 0;
    margin-right: 16px;
    z-index: 1;

    @media screen and (max-width: 992px) {
        margin: 0;
    }
`;

export const Img = styled.img`
    width: 100%;
    max-width: 40px;
    max-height: 40px;
`;

export const Content = styled.div`
    z-index: 1;
`;

export const Outline = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f0f0f0;
    border-radius: 10px;
`;

export const PhoneContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    ${props => !props.$expanded && `margin-top: 80px;`}
`; 

export const PhoneWrapper = styled.div`
    
    @media screen and (max-width: 992px) {
        position: relative;
    } 

    @media screen and (max-width: 425px) {
        transform: translateX(-25px);
    }
`;

export const ColorList = styled.ul`
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--color-white);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    transition: all 0.2s ease;
    width: 80px;
    right: -50px;
    bottom: 50%;
    transform: translateY(50%);
`;

export const Decoration = styled.img`
    position: absolute;
    top: 160px;
    left: 0;
    @media screen and (max-width: 1600px) {
        display: none;
    }
`;

export const MobileItem = styled.div`
    border-radius: 10px;
    display: flex;
    position: relative;
    cursor: pointer;
    padding: 0;
    max-width: 290px;
    margin: 40px auto 0 auto;
`;

export const MobileFigure = styled.figure`
    flex-shrink: 0;
    margin-right: 16px;
`;

export const MobileButtonWrapper = styled.div`
    text-align: center;
    margin-top: 60px;
    
    @media screen and (max-width: 425px) {
        margin-top: 40px;
    }
`;

export const ButtonWrapper = styled.div`
    text-align: center;
    padding-bottom: 20px;

    @media screen and (max-width: 425px) {
        margin-top: 40px;
    }
`;

