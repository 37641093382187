import styled, { css } from 'styled-components';
import Container from "../Container/Container";

const ContainerSmall = styled(Container)`
    max-width: 768px;
    text-align: center;

    ${props => props.center && `${center}`};
    ${props => props.mb && `${mb}`};

`;

const center = css`
    text-align: center;
`;

const mb = css`
    margin-bottom: 80px;
`;

export default ContainerSmall;