import React from 'react'
import * as S from './Process.elements';
import {Await} from "react-router-dom";
import Wrapper from "../Wrapper/Wrapper";
import ContainerSmall from "../ContainerSmall/ContainerSmall";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";
import Container from "../Container/Container";
import Card from "../Card/Card";

const Process = ({data}) => {
    return (
        <React.Suspense>
            <Await resolve={data}>
                {(data) => (
                    <Wrapper secondary="true">
                        <ContainerSmall>
                            <Heading tag="h2" textOnSurface>{data[0]?.title}</Heading>
                            <Text textOnSurface>{data[0]?.text}</Text>
                        </ContainerSmall>
                        <Container>
                            <S.USP>
                                {data[0]?.approaches.map(item => (
                                    <Card key={item?.id} image={item?.icon} title={item?.title} text={item?.text} />
                                ))}
                            </S.USP>
                        </Container>
                    </Wrapper>
                )}
            </Await>
        </React.Suspense>
    )
}

export default Process
