import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import reset from 'styled-reset-advanced';

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${normalize}

    * { 
        box-sizing: border-box;
        user-select: initial;
    }

    :root {
        /* Background Colors */
        --color-bg: #FEFEFE;
        --color-surface: #D4E7D5;
        --color-surface-30: #F2F7F2;
        --color-nav-footer: #F9FBF9;
        --color-underlay: #134E4A;
        --color-tag: #EDEDED;
        --color-tag-on-surface: #C2DCC3;
        
        /* Base Colors */
        --color-primary: #4BC17A;
        --color-white: #FFFFFF;
        --color-black: #000000;

        /* Other Colors */
        --color-error: #FF7A7A;
        --color-border: #EEEEEE;
        --color-border-hover: #C4C4C4;
        --color-info-banner-primary: #134E4A;
        --color-info-banner-secondary: #115E59;
        --color-stroke: #F4F4F4;
        --color-button-on-primary: #43AC6D;
        --color-button-on-primary-hover: #3CA064;
        --color-dropdown: #FAFAFA;

        /* Text Colors */
        --color-heading-on-bg: #171717;
        --color-text-on-bg: #374151;
        --color-text-on-bg-hover: #959595;
        --color-heading-on-surface: #04575D;
        --color-text-on-surface: #04575D;
        --color-placeholder: #DEDEDE;
        --color-switch-outer: var(--color-white);

        /* Fonts */
        --font-heading: 'Sora', sans-serif;
        --font-text: 'Open Sans', sans-serif;
        --font-button: var(--font-heading);

        /* Font Weights */
        --fw-light: 300;
        --fw-regular: 400;
        --fw-medium: 500;
        --fw-semi-bold: 600;
        --fw-bold: 700;
        --fw-extra-bold: 800;

        /* Font Sizes */
        --font-size-default: 1.6rem;
        --fs-nav-link: 1.6rem;
        --fs-nav-lang: 1.6rem;
        --fs-h1: 4rem;
        --fs-h2: 3rem;
        --fs-h3: 2.4rem;
        --fs-h3-smaller: 1.8rem; //1.8rem
        --fs-h4: 1.6rem;
        --fs-dropdown-body: 1.4rem;
        --fs-button: 1.6rem;
        --font-size-nav-sub: 1.4rem;
        --font-size-nav-contact: 1.6rem;
        --font-size-tooltip: 1.4rem;
        --fs-nav-dropdown: 1.6rem;
        --fs-tag: 1.2rem;

        /* Width */
        --width-container: 1440px;

        @media screen and (max-width: 1024px) {
            --fs-h1: 3.5rem;
            --fs-h2: 2.8rem;
        }
        
        @media screen and (max-width: 992px) {
            --fs-nav-link: 2rem;
            --fs-h4: 1.6rem;
        }

        @media screen and (max-width: 768px) {
            --fs-h1: 3.3rem;
            --fs-h2: 2.5rem;
        }
    }

    html, body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
        font-size: 62.5%;
        font-family: var(--font-text), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        background-color: var(--color-bg);
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        tab-size: 4;
        font-feature-settings: normal;
    }

    body {
        font-size: var(--font-size-default);

        @media screen and (max-width: 768px) {
            word-break: break-word;
        }
    }
    
    span {
        user-select: initial;
        
        &::selection {
            color: white;
            background: mediumseagreen;
        }
    }

    ::selection {
        color: white;
        background: mediumseagreen;
    }

    /* NProgress navigation loader bar */
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-primary);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }
`;

export default GlobalStyle;