import { Helmet } from 'react-helmet-async';
import * as S from './Privacy.elements';
import Wrapper from "../../components/Wrapper/Wrapper";
import Container from "../../components/Container/Container";
import Heading from "../../components/Heading/Heading";

const PrivacyUVSV = () => {

    return (
        <>
            <Helmet>
                <title>Youmie &mdash; Privacyverklaring U.V.S.V./N.V.V.S.U.</title>
                <meta property="og:title" content="Youmie - Privacyverklaring U.V.S.V./N.V.V.S.U." />
                <meta name="twitter:title" content="Youmie - Privacyverklaring U.V.S.V./N.V.V.S.U." />
                <link rel="canonical" href="https://youmie.nl/privacy-statement-uvsv" />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Wrapper>
                <Container>
                </Container>
                <S.StyledContainer>
                    <Heading tag="h2">Privacyverklaring van de U.V.S.V./N.V.V.S.U. en de Reünistenvereniging</Heading>

                    <S.Section>
                        <Heading tag="h3">1. Over ons</Heading>
                        <S.Text>De Utrechtsche Vrouwelijke Studenten Vereeniging / Nieuwe Vereniging van Vrouwelijke Studenten Utrecht (hierna: “<strong>U.V.S.V./N.V.V.S.U</strong>”) is de op één na grootste studentenvereniging in Utrecht en de grootste ongemengde studentenvereniging in Nederland.</S.Text>
                        <S.Text>De Reünistenvereniging Utrechtsche Vrouwelijke Studenten Vereeniging / Nieuwe Vereniging van Vrouwelijke Studenten te Utrecht (hierna: “<strong>Reünistenvereniging</strong>”) vormt een indrukwekkend netwerk van ambitieuze en inspirerende vrouwen.</S.Text>
                        <S.Text>De U.V.S.V./N.V.V.S.U en de Reünistenvereniging worden hierna samen ook wel aangeduid als “<strong>wij</strong>” of “<strong>ons</strong>”.</S.Text>
                        <S.Text>De U.V.S.V./N.V.V.S.U. en de Reünistenvereniging zijn gevestigd aan de Drift 19, 3512 BR, te Utrecht, Nederland en staan ingeschreven in het register van de Kamer van Koophandel onder de nummers: 40476235 respectievelijk 40479156.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">2. Deze Privacyverklaring</Heading>
                        <S.Text>Dit is een document waarin wij uitleggen hoe de U.V.S.V./N.V.V.S.U. en de Reünistenvereniging met jouw privacyrechten omgaan (hierna: ‘’<strong>Privacyverklaring</strong>’’). Deze Privacyverklaring is van toepassing op de verwerking van persoonsgegevens in het kader van jouw lidmaatschap en wanneer je onze website <S.Url href='https://www.uvsv.nl/home' target='_blank' rel='noreferrer'>www.uvsv.nl</S.Url> (hierna: ‘’<strong>Website</strong>’’) bezoekt.</S.Text>
                        <S.Text>De U.V.S.V./N.V.V.S.U en de Reünistenvereniging maken gebruik van dezelfde ledendatabase en zijn daarom ook wel aan te merken als (deels) gezamenlijk verwerkingsverantwoordelijken. In dit verband geven wij gezamenlijk informatie over de verwerking van jouw persoonsgegevens in deze Privacyverklaring.</S.Text>
                        <S.Text>Voor de U.V.S.V./N.V.V.S.U. en de Reünistenvereniging is de privacy van haar leden (hierna: “<strong>Leden</strong>”, of “<strong>Lid</strong>”) uiterst belangrijk. Voor de uitoefening van de activiteiten van onze verenigingen is het noodzakelijk om persoonsgegevens te verwerken. Wij verwerken en beveiligen persoonsgegevens met de grootst mogelijke zorgvuldigheid. Bij de verwerking van persoonsgegevens houden wij ons aan de eisen die de wet- en regelgeving daaraan stelt, zoals de eisen uit de Algemene Verordening Gegevensbescherming (AVG).</S.Text>
                        <S.Text>In deze Privacyverklaring wordt uitgelegd hoe we de persoonsgegevens van onze Leden omgaan. Lees deze Privacyverklaring aandachtig door zodat je weet wat jouw rechten en plichten zijn. Deze Privacyverklaring is te raadplegen, downloaden en printen via onze Website.</S.Text>
                        <S.Text>Alle leden van onze besturen en de commissies zijn tevens verplicht deze Privacyverklaring na te leven.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">3. Wat zijn persoonsgegevens?</Heading>
                        <S.Text>Met persoonsgegevens wordt alle informatie bedoeld waarmee een natuurlijk persoon direct of indirect geïdentificeerd kan worden (hierna: “<strong>Persoonsgegevens</strong>”). Zo zijn voor- en achternamen, (mobiele) telefoonnummers, (e-mail)adressen en een geboortedatum bijvoorbeeld Persoonsgegevens.</S.Text>
                        <S.Text>Persoonsgegevens hebben betrekking op een persoon, de betrokkene (hierna: “<strong>Betrokkene</strong>”). Het verzamelen, opslaan, gebruiken, bewerken, raadplegen en doorzenden van Persoonsgegevens wordt “verwerken” genoemd.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">4. Hoe verkrijgen wij Persoonsgegevens?</Heading>
                        <S.Text>De U.V.S.V./N.V.V.S.U. verzamelt en verwerkt Persoonsgegevens van Leden die aan haar worden verstrekt, bijvoorbeeld in het meest voorkomende geval bij de aanmelding als Lid of het verzoek om contact op te nemen.</S.Text>
                        <S.Text>Leden gaan na vijf jaar lidmaatschap automatisch over naar de Reünistenvereniging. Vanaf dat moment verwerkt de Reünistenvereniging in beginsel jouw Persoonsgegevens, zoals deze in onze gezamenlijke database staan opgenomen, bijvoorbeeld om contact met jou op te kunnen nemen en jou te (blijven) informeren over komende activiteiten voor reünisten.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">5. Is het verplicht om Persoonsgegevens te verstrekken?</Heading>
                        <S.Text>Je hebt altijd zelf de keuze of je Persoonsgegevens aan ons wil verstrekken, maar zonder jouw persoonsgegevens kun je geen Lid worden en/of blijven.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">6. Welke (categorieën van) Persoonsgegevens hebben wij en voor welke doeleinden gebruiken wij die?</Heading>
                        <S.Text>Om je een indruk te geven van de Persoonsgegevens die wij van jou verwerken, vind je hieronder een schema. Je vindt daarin voorbeelden van (categorieën van) Persoonsgegevens die wij verwerken en waarom wij die Persoonsgegevens verwerken.</S.Text>
                        <S.Text>Wij verwerken primair de volgende (Persoons)gegevens van Leden:</S.Text>
                        <S.TableWrapper>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Persoonsgegevens</th>
                                        <th>Doel van het gebruikt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>NAWTE-gegevens</td>
                                        <td>
                                            <S.List>
                                                <S.Item>Om jou te kunnen aanmelden als lid;</S.Item>
                                                <S.Item>Om jou op te kunnen nemen in ons ledenbestand;</S.Item>
                                                <S.Item>Om jou onze ledenmailingen toe te kunnen zenden;</S.Item>
                                                <S.Item>Om jou op de hoogte te kunnen houden van onze ledenactiviteiten;</S.Item>
                                                <S.Item>Om contact met jou op te kunnen nemen.</S.Item>
                                            </S.List>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Foto's en video-opnames</td>
                                        <td>Om jou en andere leden te kunnen informeren over onze ledenactiviteiten.</td>
                                    </tr>
                                    <tr>
                                        <td>Bankrekeninggegevens</td>
                                        <td>Om de door jou verschuldigde contributiegelden in rekening te brengen/af te laten schrijven.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </S.TableWrapper>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">7. Wat is de wettelijke grondslag van de verwerking?</Heading>
                        <S.Text>Om Persoonsgegevens te mogen verwerken dient er een wettelijke grondslag te zijn. U.V.S.V./N.V.V.S.U. en de Reünistenvereniging verwerken Persoonsgegevens primair op basis van toestemming en/of de uitvoering van de lidmaatschapsovereenkomst, dan wel een gerechtvaardigd belang. De Reünistenvereniging heeft een gerechtvaardigd belang om jouw persoonsgegevens te ontvangen, waarmee je na vijf jaar automatisch Lid wordt van de Reünistenvereniging. Dit is om een zo groot mogelijk netwerk in stand te houden, verbinding te behouden en elkaar te ondersteunen. Uiteraard word je hierover uitgebreid geïnformeerd.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">8. Delen we jouw Persoonsgegevens met derden?</Heading>
                        <S.Text>Wij delen jouw Persoonsgegevens in principe niet met derden, maar kunnen wel derden inschakelen bij de uitoefening van onze activiteiten. Voor zover deze derden daarbij jouw Persoonsgegevens verwerken, doen zij dit in hoedanigheid van verwerker voor ons en hebben wij de vereiste technische en organisatorische maatregelen getroffen om te verzekeren dat jouw Persoonsgegevens in overeenstemming met deze Privacyverklaring worden verwerkt (op basis van een verwerkersovereenkomst). Verder verstrekken wij jouw Persoonsgegevens alleen aan toezichthouders, fiscale autoriteiten en opsporingsinstanties als wij daartoe wettelijk verplicht zijn.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">9. Hoe worden de Persoonsgegevens beveiligd?</Heading>
                        <S.Text>Wij handhaven te allen tijde een beveiligingsniveau bij de verwerking van Persoonsgegevens dat gezien de stand van de techniek voldoende behoort te zijn om ongeoorloofde toegang tot, aanpassing, openbaarmaking en/of verlies van Persoonsgegevens te voorkomen.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">10. Hoe lang worden de Persoonsgegevens bewaard?</Heading>
                        <S.Text>Wij bewaren jouw Persoonsgegevens zo lang als dat noodzakelijk is voor het bereiken van bovenstaande doeleinden. Wanneer je je uitschrijft als lid, worden jouw Persoonsgegevens in principe na twee (2) jaar verwijderd.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">11. Links naar websites van anderen</Heading>
                        <S.Text>Onze Website kan links naar websites van derden en naar social media platformen bevatten. Zodra je een link hebt gebruikt om onze Website te verlaten, moet je er rekening mee houden dat wij geen controle meer hebben over de wijze waarop de website die je bezoekt omgaat met jouw Persoonsgegevens en jouw privacy. Wij zijn niet verantwoordelijk en/of aansprakelijk voor de inhoud van deze websites en ook niet voor de wijze waarop deze websites met jouw Persoonsgegevens en privacy omgaan. Wij raden je aan vooraf de privacyverklaring van de desbetreffende website of het desbetreffende social media platform te raadplegen.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">12. Welke rechten heb ik als Betrokkene?</Heading>
                        <S.Text>Wij vinden het belangrijk dat jouw Persoonsgegevens juist en up-to-date zijn. Op grond van de privacywetgeving heb je als Betrokkene het:</S.Text>
                        <S.List>
                            <S.Item>recht op inzage in de Persoonsgegevens die wij van en/of over jou hebben;</S.Item>
                            <S.Item>recht op rectificatie van de Persoonsgegevens die wij van en/of over jou hebben;</S.Item>
                            <S.Item>recht op verwijdering van de Persoonsgegevens die wij van en/of over jou hebben;</S.Item>
                            <S.Item>recht op beperking van de verwerking van de Persoonsgegevens die wij van en/of over jou hebben;</S.Item>
                            <S.Item>recht op gegevensoverdraagbaarheid;</S.Item>
                            <S.Item>recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens (hierna: “<strong>AP</strong>”).</S.Item>
                        </S.List>
                        <S.Text>Als je één van de bovengenoemde rechten uit wilt oefenen kun je contact opnemen met het Bestuur door middel van het sturen van een e-mail naar bestuur@uvsv.nl.</S.Text>
                        <S.Text>Wij streven er naar jouw verzoek binnen vier weken na ontvangst af te handelen.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">13. Wat moet ik doen als ik een klacht heb?</Heading>
                        <S.Text>Wanneer je een klacht hebt over de wijze waarop wij jouw Persoonsgegevens verwerken, neem dan contact met ons op. Je kunt jouw klacht per e-mail toesturen naar bestuur@uvsv.nl</S.Text>
                        <S.Text>Wij streven er naar jouw klacht binnen vier weken na ontvangst af te handelen. Mocht de wijze waarop wij jouw klacht hebben afgehandeld onverhoopt niet aan je verwachtingen voldoen, dan kun je contact opnemen met de AP. De AP houdt toezicht op de naleving van de privacywetgeving. Je kunt bij de AP altijd een klacht indienen.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">14. Waar kan ik terecht met vragen en/of opmerkingen?</Heading>
                        <S.Text>Wanneer je vragen en/of opmerkingen hebt over deze Privacyverklaring of de wijze waarop wij jouw Persoonsgegevens verwerken, neem dan contact met ons op door middel van het zenden van een e-mail naar bestuur@uvsv.nl. Wij streven er naar om binnen vier weken na ontvangst op jouw vraag en/of opmerking te reageren.</S.Text>
                    </S.Section>

                    <S.Section>
                        <Heading tag="h3">15. Kan deze Privacyverklaring worden gewijzigd?</Heading>
                        <S.Text>Deze Privacyverklaring kan door ons worden gewijzigd. Zulke gewijzigde voorwaarden zijn van kracht vanaf het moment dat deze worden weergegeven op de Website. Wij adviseren je daarom regelmatig deze Privacyverklaring na te lezen op eventuele wijzigingen. Ook kunnen we je over wijzigingen informeren via onze nieuwsbrieven.</S.Text>
                    </S.Section>
                </S.StyledContainer>
            </Wrapper>

        </>
    );
}

export default PrivacyUVSV
