import React, { useState, useEffect } from 'react'
import * as S from './CardParallax.elements';
import decoration from '../../assets/img/decorations/deco-quarter-circles.svg';
import squiggly from '../../assets/img/decorations/deco-squiggly-line-right-2.svg';
import { HiArrowSmRight } from 'react-icons/hi';
import Container from "../Container/Container";
import Wrapper from "../Wrapper/Wrapper";
import Heading from "../Heading/Heading";
import Button from "../Button/Button";
import Card from "../Card/Card";
import {parseHTMLText} from "../../helpers/parseHTMLText";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {Await} from "react-router-dom";

const CardParallax = ({data}) => {
    const {langForInUrl} = useCurrentLangCode();

    // Handle parallax on mobile
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if(window.innerWidth <= 1024) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Wrapper first="true">
            <Container>
                <React.Suspense>
                    <Await resolve={data}>
                        {(data) => (
                            <S.Flex>
                                <S.Box>
                                    <S.Decoration src={decoration} alt='' />
                                    <Heading tag="h2">{data[0]?.title}</Heading>
                                    <S.StyledRichText dangerouslySetInnerHTML={{__html: parseHTMLText(data[0]?.text, langForInUrl)}} />
                                    <Button primary="true" to={`${langForInUrl}/${data[0]?.buttonLink}`}>{data[0]?.buttonLabel} <HiArrowSmRight/></Button>
                                </S.Box>

                                <S.Box>
                                    <S.ParallaxGrid>
                                        {mobile ?
                                            <>
                                                {data[0]?.advantages?.map((item) => (
                                                    <Card key={item.id} title={item?.title} text={item?.text} image={item?.icon} margin="0" />
                                                ))}
                                            </>
                                            :
                                            <>
                                                <S.ParallaxItem speed={-15}>
                                                    {data[0]?.advantages?.slice(0, 2).map((item) => (
                                                        <Card key={item.id} title={item?.title} text={item?.text} image={item?.icon} margin="0" />
                                                    ))}
                                                </S.ParallaxItem>
                                                <S.ParallaxItem speed={15}>
                                                    {data[0]?.advantages?.slice(2).map((item) => (
                                                        <Card key={item.id} title={item?.title} text={item?.text} image={item?.icon} margin="0" />
                                                    ))}
                                                </S.ParallaxItem>
                                            </>
                                        }
                                    </S.ParallaxGrid>
                                </S.Box>
                            </S.Flex>
                        )}
                    </Await>
                </React.Suspense>
            </Container>
            <S.Squiggly src={squiggly} alt='' />
        </Wrapper>
    )
}

export default CardParallax
