import {createContext, useContext, useState} from "react";

export const PopupLinkContext = createContext(null);

export default function PopupLinkProvider({children}) {
    const [viaInternalLink, setViaInternalLink] = useState(false);

    return (
        <PopupLinkContext.Provider value={{viaInternalLink, setViaInternalLink}}>
            {children}
        </PopupLinkContext.Provider>
    )
}

export const usePopupLinkContext = () => useContext(PopupLinkContext);