import React from 'react'
import * as S from './IntegrationCloudInvert.elements';
import congressus from '../../assets/img/blocks/integrationCloud/congressus.jpg';
import creo from '../../assets/img/blocks/integrationCloud/creo.jpg';
import exact from '../../assets/img/blocks/integrationCloud/exact.jpg';
import genkgo from '../../assets/img/blocks/integrationCloud/genkgo.jpg';
import mailchimp from '../../assets/img/blocks/integrationCloud/mailchimp.jpg';
import Heading from "../Heading/Heading";
import Text from "../Text/Text";

const IntegrationCloudInvert = ({data}) => {

    const logos = [
        { id: 1, name: 'Congressus', image: congressus, size: 22, top: 10, left: 50, duration: 9 },
        { id: 2, name: 'Creo', image: creo, size: 17, top: 25, left: 23, duration: 8 },
        { id: 3, name: 'Genkgo', image: genkgo, size: 11, top: 52, left: 29, duration: 8.5 },
        { id: 4, name: 'Exact', image: exact, size: 18, top: 44, left: 46, duration: 8.8 },
        { id: 5, name: 'Mailchimp', image: mailchimp, size: 15, top: 70, left: 33, duration: 8.2 },
    ]

    return (
        <S.Flex>
            <S.TextBox>
                <Heading tag="h2">{data[0]?.connectTitle}</Heading>
                <Text>{data[0]?.connectText}</Text>
            </S.TextBox>
            <S.CloudBox>
                <S.IntegrationCloud>
                    {logos.map(item => (
                        <S.Logo key={item.id} size={item.size} top={item.top} left={item.left} duration={item.duration}>
                            <img src={item.image} alt={'Logo van ' + item.name} loading='lazy'/>
                        </S.Logo>
                    ))}
                </S.IntegrationCloud>
            </S.CloudBox>
        </S.Flex>
    )
}

export default IntegrationCloudInvert
