import React from 'react'
import * as S from './TriplePhone.elements';
import Wrapper from "../Wrapper/Wrapper";
import ContainerSmall from "../ContainerSmall/ContainerSmall";
import Text from "../Text/Text";
import Heading from "../Heading/Heading";

const TriplePhone = ({title, text, image}) => {

    return (
        
        <Wrapper>
            <ContainerSmall as="header">
                {title.split(',').map((heading, index) => <Heading key={index} tag="h2">{heading}</Heading>)}
                <Text>{text}</Text>
            </ContainerSmall>
            <S.PhoneWrapper>
                <ContainerSmall>
                    <img src={image} alt='' />
                </ContainerSmall>
            </S.PhoneWrapper>
        </Wrapper>
    )
}

export default TriplePhone
