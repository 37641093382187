import styled from 'styled-components';
import Text from '../../components/Text/Text';
import Container from "../../components/Container/Container";

export const AtmosphericContainer = styled.div`
    position: relative;
`;

export const Outer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 12 / 5;
    background-color: var(--color-background);
    
    @supports not (aspect-ratio: 12 / 5) {
        overflow: hidden;
        padding-top: 41.67%;
    }
`;

export const Inner = styled.div`
    padding: 60px 0;
    min-height: 100%;
    background-image: url(${({$background}) => $background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    
    @media screen and (max-width: 768px) {
        padding: 30px 0 400px;
    }

    @media screen and (max-width: 425px) {
        padding: 20px 0 400px;
    }
`;

export const Flex = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const Card = styled.article`
    background-color: var(--color-white);
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 460px;
    
    @media screen and (max-width: 768px) {
        max-width: unset;
    }
`;

export const StyledText = styled(Text)`
    margin-bottom: 40px;
    
    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
`;