import styled from 'styled-components';

const ContainerMedium = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 48px;
    padding-right: 48px;

    @media screen and (max-width: 768px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media screen and (max-width: 425px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export default ContainerMedium;