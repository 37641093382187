import {defer, useLoaderData} from "react-router-dom";
import InfoBanner from "../../components/InfoBanner/InfoBanner";
import CardParallax from "../../components/CardParallax/CardParallax";
import HomeHero from "../../components/HomeHero/HomeHero";
import TextImageList from "../../components/TextImageList/TextImageList";
import IntegrationParallax from "../../components/IntegrationParallax/IntegrationParallax";
import TextParallax from "../../components/TextParallax/TextParallax";
import styled from "styled-components";
import Atmospheric from "../../components/Atmospheric/Atmospheric";
import TextImage from "../../components/TextImage/TextImage";
import teamInOffice from "../../assets/img/blocks/textImage/team-in-office.jpg";
import TestimonialSlider from "../../components/TestimonialSlider/TestimonialSlider";
import {Seo} from "../../components/Seo/Seo";
import {useTranslation} from "react-i18next";

const Spacing = styled.div`
    padding: 80px 0;
    
    @media screen and (max-width: 768px) {
        padding: 40px 0;
    }
`;

export async function homeLoader({request, params}) {
    const lang = params?.lang;

    const homeData = await fetch(`${process.env.REACT_APP_API_URL}/home${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    const advantagesData = fetch(`${process.env.REACT_APP_API_URL}/advantages${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    const textImageData = fetch(`${process.env.REACT_APP_API_URL}/textimage${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    const partnersData = fetch(`${process.env.REACT_APP_API_URL}/partners${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    const testimonialsData = fetch(`${process.env.REACT_APP_API_URL}/testimonials${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    return defer({homeData, advantagesData, textImageData, partnersData, testimonialsData});
}

const Home = () => {
    const {t} = useTranslation();
    const {homeData, advantagesData, textImageData, partnersData, testimonialsData} = useLoaderData();

    return (
        <>
            <Seo
                title={t('general.title')}
                description={homeData[0]?.metaDescription}
                slug="/"
            />

            <HomeHero data={homeData}/>
            <InfoBanner data={homeData}/>
            <CardParallax data={advantagesData}/>
            <Atmospheric data={homeData}/>
            <TextImageList data={textImageData}/>
            <IntegrationParallax data={partnersData} />
            <Spacing>
                <TextImage image={teamInOffice} radius="true" title={homeData[0]?.optionsTitle} text={homeData[0]?.optionsText} reverse={false} />
            </Spacing>
            <TextParallax data={homeData}/>
            <TestimonialSlider data={testimonialsData} />
        </>
    )
}

export default Home
