import styled, {css} from "styled-components";
import Text from "../../components/Text/Text";
import ContainerMedium from "../ContainerMedium/ContainerMedium";

export const PricingContainer = styled.div`
    margin-top: ${({$hasSwitch}) => $hasSwitch ? '-322px' : '-262px'};
    position: relative;
    z-index: 95;
    
    @media screen and (max-width: 1280px) {
        margin-top: -300px;
    }
`;

export const StyledContainerMedium = styled(ContainerMedium)`
    position: relative;
    top: -38px;
    
    @media screen and (max-width: 768px) {
        top: unset;
    }
`;

export const Packages = styled.div`
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns >= 3 ? 3 : $columns}, 1fr);
    gap: 38px;
    overflow-x: auto;
    margin-left: -48px;
    width: calc(100% + 96px);
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 10px;

    @media screen and (max-width: 768px) {
        gap: 18px;
        margin-left: -24px;
        width: calc(100% + 48px);
        padding-left: 24px;
        padding-right: 24px;
    }

    @media screen and (max-width: 425px) {
        margin-left: -16px;
        width: calc(100% + 32px);
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const PackageCard = styled.article`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${({$isPopular}) => $isPopular ? 'var(--color-primary)' : 'var(--color-white)'};
    padding: 30px;
    border-radius: 24px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
    ${({$isPopular}) => !$isPopular && 'margin-top: 38px;'};
    ${({$isPopular}) => $isPopular && 'align-self: start;'};
    max-width: 100%;
    width: 330px;
    
    @media screen and (max-width: 768px) {
        ${({$isPopular}) => !$isPopular && 'margin-top: 0;'};
        ${({$isPopular}) => $isPopular && 'align-self: unset;'};
    }
`;

export const PackageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const PackageLabel = styled.div`
    font-family: var(--font-button);
    font-weight: var(--fw-bold);
    line-height: 1.2;
    border-radius: 24px;
    font-size: var(--fs-button);
    color: var(--color-primary);
    text-align: center;
    display: inline-block;
    background-color: var(--color-white);
    padding: 12px 24px;
`;

export const PackageFigure = styled.figure``;

export const PackageImage = styled.img`
    display: block;
    width: 50px;
    height: 50px;
`;

export const PackageWrapper = styled.div`
    padding-top: 20px;
    margin-top: auto;
`;

export const PriceBox = styled.div`
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: ${({$isPopular}) => $isPopular ? '2px solid rgba(255, 255, 255, 0.14)' : '2px solid rgba(55, 65, 81, 0.07)'};
    display: flex;
    align-items: center;
    gap: 13px;
`;

export const Price = styled.p`
    flex-shrink: 0;
    font-family: var(--font-heading);
    color: ${({$isPopular}) => $isPopular ? 'var(--color-white)' : 'var(--color-heading-on-bg)'};
    line-height: 1.4;
    margin: 0;
    font-size: 48px;
    font-weight: var(--fw-bold);
`;

export const VariablePrice = styled(Text)`
    line-height: 1.25;
    
    strong {
        font-family: var(--font-heading);
        color: ${({white}) => white ? 'var(--color-white)' : 'var(--color-heading-on-bg)'};
        font-size: var(--fs-h4);
        font-weight: var(--fw-bold);
        vertical-align: middle;
    }
`;

export const PackageButtonWrapper = styled.div`
    text-align: right;
    margin-top: 30px;
`;

export const TextGrid = styled.div`
    padding-top: 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    
    @media screen and (max-width: 1280px) {
        gap: 38px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 0;
    }
`;

export const PricingSwitchWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
`;

export const SwitchOuter = styled.div`
    background-color: var(--color-switch-outer);
    border-radius: 25px;
    display: flex;
    align-items: center;
`;

export const SwitchItem = styled.div`
    cursor: pointer;
    position: relative;
    font-family: var(--font-button);
    font-weight: var(--fw-bold);
    line-height: 1.2;
    border-radius: 24px;
    font-size: var(--fs-button);
    text-align: center;
    display: inline-block;
    background-color: ${({$active}) => $active ? 'var(--color-primary)' : 'transparent'};
    padding: 12px 24px;
    color: ${({$active}) => $active ? 'var(--color-white)' : 'var(--color-primary)'};
    transition: .1s ease background-color;

    &:hover, &:focus {
        background-color: var(--color-primary);
        color: ${({$active}) => $active ? 'var(--color-white)' : 'var(--color-white)'};
    }
`;

export const TableContainer = styled.div`
    position: relative;
    z-index: 1;
    max-width: var(--width-container);
    margin: 60px auto 0 auto;
    
    ${({$isTableOpen}) => !$isTableOpen && css`
        max-height: 675px;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 162px;
            pointer-events: none;
            left: 0;
            background: linear-gradient(180deg, rgba(254, 254, 254, 0) 0%, rgba(254, 254, 254, 1) 100%);
            z-index: 1;
        }
    `};
`;

export const TableOpenTextWrapper = styled.div`
    max-width: 480px;
    margin: 0 auto;
    padding: 30px 0;
`;