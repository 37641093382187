import styled from 'styled-components';
import Slider from 'react-slick';

export const Flex = styled.div`
    display: flex;
    border-radius: 10px;
    background-color: #134E4A;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const SliderWrapper = styled.div`
    flex: 1;
`;


export const StyledSlider = styled(Slider)`
    width: 40%;
    overflow: hidden;
    height: 340px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @media screen and (max-width: 768px) {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0;
        width: 100%;
    }

    .slick-dots {
        top: 50%;
        right: 0;
        bottom: unset;
        width: unset;
        transform: translateY(-50%);

        li {
            display: block;
            transition: all .2s;
            
            button {
                transition: all .2s;
                background-color: #D4E7D5;
                border-radius: 7px;
                width: 14px;
                height: 14px;

                &:before {
                    content: none;
                }
            }

            &.slick-active {
                transition: all .2s;
                margin-bottom: 4px;

                button {
                    background-color: #134E4A;
                    height: 18px;
                }
            }
        }
    }

    .slick-slide {
        border: none;
        overflow: hidden;
        
        @media screen and (max-width: 768px) {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 0;
        }

        div {
            outline: none;
        }
    }
`;

export const Content = styled.article`
    width: 60%;
    padding: 40px;
    display: flex;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 24px;
    }

    div {
        position: relative;
        z-index: 1;
        align-self: center;
    }
    
    h2 {
        font-family: 'Sora', sans-serif;
        margin: 8px 0;
        color: #fff;
        line-height: 1.25;
        transition: font-size .2s ease;
        font-size: 3rem;
        font-weight: 700;
    }
    
    p {
        color: #fff;
        font-size: 1.8rem;
        line-height: 1.5;
        margin: 8px 0;
        transition: font-size .2s ease;

        @media screen and (max-width: 576px) {
            font-size: 1.6rem;
        }
    }
`;

export const Decoration = styled.img`
    position: absolute;
    bottom: 0;
    right: 50px;

    @media screen and (max-width: 1024px) {
        right: -150px;
    }
`;

export const Slide = styled.div`
    height: 340px;

    img {
        width: 100%;
        height: 100%;
        /* max-height: 340px; */
        object-fit: cover;
    }

`;
