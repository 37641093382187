import styled from 'styled-components';
import Container from "../Container/Container";

export const Footer = styled.footer`
    background-color: #171717;
    padding-bottom: 80px;
    margin-top: auto;
    position: relative;
`;

export const FooterContact = styled.div`
    background-color: var(--color-primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 48px;
    position: relative;
    top: -100px;
    overflow: hidden;

    a {
        z-index: 1;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        padding: 24px;
    }
`;

export const Body = styled.div`
    width: 50%;
    z-index: 1;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
    }
`;

export const Decoration = styled.img`
    position: absolute;
    bottom: 0;
    right: 70px;

    @media screen and (max-width: 768px) {
        max-width: 250px;
        right: -50px;
    }
`;

export const FooterContainer = styled(Container)`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const AboutYoumie = styled.div`
    flex: 1;

    @media screen and (max-width: 1024px) {
        flex: 100%;
        margin-bottom: 40px;
    }

    p {
        max-width: 70%;
        color: #ffffff;
        font-size: 1.6rem;
        line-height: 1.35;
        margin: 16px 0;
    
        @media screen and (max-width: 576px) {
            font-size: 1.4rem;
        }

        a {
            font-weight: var(--fw-semi-bold);
            color: #fff;
            font-size: 1.6rem;
            line-height: 1.35;
            background: linear-gradient(to bottom, #3d3d3d 0%, #3d3d3d 100%);
            background-position: 0 100%;
            background-repeat: repeat-x;
            background-size: 4px 50%;
            text-decoration: none;
            transition: background-size .2s;

            :hover {
                background-size: 4px 100%;
            }

            @media screen and (max-width: 576px) {
                font-size: 1.4rem;
            }
        }
    }
`;

export const Logo = styled.img`
    display: block;
    width: 150px;
    max-width: 100%;
    height: 50px;
    object-fit: contain;
    margin-bottom: 20px;
`;

export const Badge = styled.a`
    display: inline-block;
    
    img {
        object-fit: contain;
        width: 100%;
        max-width: 200px;
        height: 100%;
        max-height: 30px;
    }
`;

export const Nav = styled.nav`
    flex: 1;

    @media screen and (max-width: 576px) {
        flex: 0 0 50%;
        max-width: 50%;
    }

    h3 {
        font-family: var(--font-heading);
        margin: 8px 0;
        color: var(--color-white);
        line-height: 1.25;
        font-size: 2.2rem;
        font-weight: 700;
    }

    li {
        font-size: 1.6rem;
        color: var(--color-white);
        margin: 8px 0;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    a {
        text-decoration: none;
        margin: 16px 0;
        color: var(--color-white);
        font-size: 1.6rem;
        line-height: 1.35;
        display: flex;
        gap: 10px;

        &:hover {
            text-decoration: underline;
        }
        
        svg {
            flex-shrink: 0;
            font-size: 2.2rem;
            color: var(--color-primary);
        }

        &.increaseLineheight {
            line-height: 1.5;
        }
    
        @media screen and (max-width: 576px) {
            font-size: 1.4rem;
        }
    }
`;

export const IconList = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 24px;
    
    a {
        text-decoration: none;
        color: inherit;
        margin: 0;
        
        svg {
            font-size: 2.8rem;
            color: var(--color-primary);
            
            @media screen and (max-width: 576px) {
                font-size: 2.2rem;
            }
        }
    }
`;

export const SubFooter = styled(Container)`
    margin-top: 80px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
`;

export const StyledText = styled.p`
    color: var(--color-white);
    font-size: var(--font-size-default);
    font-weight: var(--fw-medium);
    line-height: 1.5;
    opacity: .5;
    
    &:not(:last-of-type) {
        &:after {
            content: "|";
            margin-left: 20px;
        }
    }
    
    a {
        &:hover {
            text-decoration: underline;
        }
    }
`;