import React from 'react'
import * as S from './FeatureList.elements';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {useWindowDimensions} from "../../hooks";
import Wrapper from "../Wrapper/Wrapper";
import Container from "../Container/Container";
import Heading from "../Heading/Heading";
import {Await} from "react-router-dom";
import FeatureCard from "../FeatureCard/FeatureCard";

const FeatureList = ({data}) => {
    const {width} = useWindowDimensions();
    let isMobile = width <= 768;

    return (
        <Wrapper id="lijst">
            <React.Suspense>
                <Await resolve={data}>
                    {(data) => (
                        <Container>
                            <Heading tag="h2">{data[0]?.title}</Heading>
                            <S.StyledText>{data[0]?.text}</S.StyledText>
                        </Container>
                    )}
                </Await>
            </React.Suspense>

            <Container>
                <S.Flex>
                    <S.Aside>
                        {!isMobile && (
                            <React.Suspense fallback={
                                Array.from({ length: 8 }, (_, index) => (
                                    <S.ScrollLink key={index} as="div"><Skeleton width={100} /></S.ScrollLink>
                                ))
                            }>
                                <Await resolve={data}>
                                    {(data) => (
                                        data[0]?.featureTags.map((item) => (
                                            <S.ScrollLink
                                                key={item.slug}
                                                activeClass="feature--active"
                                                to={item.slug}
                                                spy={true}
                                                smooth={true}
                                                offset={-130}
                                                duration={500}
                                            >
                                                <span className="link-circle"></span>
                                                {item.title}
                                            </S.ScrollLink>
                                        ))
                                    )}
                                </Await>
                            </React.Suspense>
                        )}
                    </S.Aside>
                    <S.Section>
                        <React.Suspense fallback={
                            <S.FeatureSection>
                                <Heading tag="h4"><Skeleton width={80} /></Heading>
                                <S.Items>
                                    {Array.from({ length: 5 }, (_, index) => (
                                        <FeatureCard key={index} loading="true" />
                                    ))}
                                </S.Items>
                            </S.FeatureSection>
                        }>
                            <Await resolve={data}>
                                {(data) => (
                                    data[0]?.featureTags?.map((tag, index) => (
                                        <S.FeatureSection key={tag.id} id={isMobile ? `section-${index}` : tag.slug}>
                                            {isMobile && <S.Offset id={tag.slug}/>}
                                            <Heading tag="h4">{tag.title}</Heading>
                                            <S.Items>
                                                {tag.features.map((item) => (
                                                    <FeatureCard key={item.id} data={item} />
                                                ))}
                                            </S.Items>
                                        </S.FeatureSection>
                                    ))
                                )}
                            </Await>
                        </React.Suspense>
                    </S.Section>
                </S.Flex>
            </Container>
        </Wrapper>
    );
}

export default FeatureList
