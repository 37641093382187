import {languagesData} from '../../constants/languages';

export async function langCheckLoader({params}) {
    const lang = params?.lang;

    if(lang) {
        const validLang = languagesData.find(item => item.code === lang);

        if(!validLang){
            throw new Response("Page is not found", { status: 404 });
        }
    }

    return true;
}