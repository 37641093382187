export const redirectPermanently = (location) => {
    return new Response("", {
        status: 301,
        headers: {
            Location: location,
        },
    })
};

export const oldUrls = [
    {
        oldPath: "/koppelingen",
        newPath: "/"
    },
];