import styled, { css } from 'styled-components';

const expand = css`
    border-radius: 10px 0 0 10px;
    justify-content: center; 
    align-items: center;

    img {
        border-radius: 10px;
        transform: none;
    }
    
    div {
        background-color: #fff;
        position: absolute;
        left: 99%;
        width: max-content;
        height: 100%;
        border-radius: 0 10px 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 15px 5px 5px;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.03);

        p {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.2;

            span {
                font-size: 1.4rem;
                margin-left: 10px;
                width: 100%;
                font-weight: 500;

                &:before {
                    margin-right: 10px;
                    vertical-align: middle;
                    content: '';
                    width: 5px;
                    height: 5px;
                    background-color: var(--color-primary);
                    border-radius: 50%;
                    display: inline-block;
                }
            }
        }
        & > span {
            margin-top: 5px;
            display: block;
            width: 40%;
            height: 15px;
            border-radius: 5px;
            background-color: #f4f4f4;
        }
    }

    &:hover {
        border-radius: 10px 0 0 10px;
        
        img {
            border-radius: 10px;
        }
    }

    &[data-tooltip] {

        &:hover:before, &:hover:after {
            visibility: hidden;
            opacity: 0;
        } 
    }
`;

export const CustomerContainer = styled.div`
    margin-top: -300px;
    position: relative;
    margin-bottom: 160px;
    z-index: 95;
    
    @media screen and (max-width: 1024px) {
        margin-bottom: 60px;
    }
    
    @media screen and (max-width: 425px) {
        margin-bottom: 0;
        margin-top: -230px;
    }

    @media screen and (max-width: 375px) {
        margin-bottom: 0;
        margin-top: -160px;
    }
`;

export const Img = styled.img`
    width: 100%;
    max-width: 768px;
    margin: auto;
    display: block;
`;

export const FloatingElement = styled.span`
    position: absolute !important;
    /* cursor: pointer; */
    display: flex;
    top: ${props => props.top && `${props.top}%`};
    right: ${props => props.right && `${props.right}%`};
    bottom: ${props => props.bottom && `${props.bottom}%`};
    left: ${props => props.left && `${props.left}%`};
    transition: transform .2s linear, top .1s ease, left .1s ease, bottom .1s ease, right .1s ease, border-radius .2s ease;
    transform: translate3d(0, 0, 0);
    
    ${props => props.isRound && css`
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.03);
        background-color: #ffffff;
        padding: 5px;
        border-radius: 50%;
        
        img {
            width: ${props => `${props.width}px`};
            height: ${props => `${props.height}px`};
            border-radius: 50%;
            transform: ${props => props.rotate && `rotate(${props.rotate}deg)`} ${props => props.scale && `scale(${props.scale})`} ;
            transition: transform .2s ease, border-radius .2s ease;
        }
        
        &:hover {
            border-radius: 10px;
            
            img {
                border-radius: 10px;
                transform: none;
            }
        }
    `}

    ${props => !props.isRound && css `

        img {
            max-width: 300px;
            width: 100%;
        }
    `}
        
    &[data-tooltip] {
        position: relative;
        z-index: 999;
        /* cursor: pointer; */

        &:before, &:after {
            transition: all .2s ease;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            bottom: 110%;
            left: 50%;
        }

        &:before {
            margin-bottom: 5px;
            margin-left: -75px;
            padding: 12px 16px;
            width: 150px;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: ${props => props.color};
            color: #fff;
            content: attr(data-tooltip);
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.2;
        }

        &:after {
            margin-left: -5px;
            margin-top: -10px;
            width: 0;
            border-top: 5px solid ${props => props.color}; 
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            content: " ";
            font-size: 0;
            line-height: 0;
        }

        &:hover:before, &:hover:after {
            visibility: visible;
            opacity: 1;
        } 
    }

    ${props => props.expand === true ? `${expand}` : ''};
`;