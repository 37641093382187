import styled, { css } from 'styled-components';

const Container = styled.div`
    position: relative;
    z-index: 1;
    max-width: var(--width-container);
    margin: 0 auto;
    padding-left: 48px;
    padding-right: 48px;

    ${(props) => props.center && `${center}`};

    @media screen and (max-width: 768px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media screen and (max-width: 425px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

const center = css`
    text-align: center;
`;

export default Container;