import styled from 'styled-components';

export const Grid = styled.section`
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    margin-top: 80px;

    @media screen and (max-width: 375px) {
        grid-template-columns: 1fr;
    }
`;

export const Decoration = styled.img`
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;