import styled from "styled-components";
import Container from "../../components/Container/Container";
import {RichText} from "../../components/Text/Text";

export const StyledContainer = styled(Container)`
    max-width: 768px;
`;

export const Paragraph = styled.section`
    
    &:not(:last-of-type) {
        margin-bottom: 80px;
    }
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 14px;
`;

export const StyledRichText = styled(RichText)`

    ul {
        display: unset;
    }
`;