import styled from "styled-components";

export const HeroOverlap = styled.div`
    margin-top: -300px;
    position: relative;
    margin-bottom: 80px;
    z-index: 95;
    
    @media screen and (max-width: 992px) {
        margin-bottom: 60px;
    }
    
    @media screen and (max-width: 576px) {
        margin-top: -150px;
    }
`;

export const Outer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 17px;
    overflow: hidden;

    @supports not (aspect-ratio: 16 / 9) {
        padding-top: 56.25%;
    }
`;

export const Inner = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        inset: 0;
    }
`;