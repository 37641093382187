import styled from 'styled-components';

export const Item = styled.article`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Img = styled.img`
    width: 350px;
    height: 350px;

    @media screen and (max-width: 375px) {
        width: 100%;
        height: auto;
    }
`;