import React from 'react'
import * as S from './TextHero.elements';
import Heading from "../Heading/Heading";
import ContainerSmall from "../ContainerSmall/ContainerSmall";
import {RichText} from "../Text/Text";

const TextHero = ({title, text}) => {

    return (
        <S.StyledTextHero>
            <ContainerSmall>
                <Heading tag="h1" textOnSurface>{title}</Heading>
                <RichText dangerouslySetInnerHTML={{__html: text}} />
            </ContainerSmall>

            <svg viewBox="0 0 500 40" preserveAspectRatio="none">
                <path d="M0 40 0 0Q250 40 500 0L500 40Z" fill="#FEFEFE" />
            </svg>
        </S.StyledTextHero>
    )
}

export default TextHero