import React from 'react'
import * as S from './Card.elements';
import Heading from "../Heading/Heading";
import Text from "../Text/Text";

const Card = ({image, title, text, ...props}) => {
    return (
        <S.Card {...props}>
            <S.Figure>
                <S.Img src={image} alt="" />
            </S.Figure>
            <Heading tag="h4">{title}</Heading>
            <Text>{text}</Text>
        </S.Card>
    )
}

export default Card
