import React from 'react'
import * as S from './TestimonialBlock.elements';
import decoration from '../../assets/img/decorations/deco-quarter-circles.svg';
import Wrapper from "../Wrapper/Wrapper";
import {parseHTMLText} from "../../helpers/parseHTMLText";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import sliderDecoration from '../../assets/img/decorations/deco-slider.svg';
import {addWordBreak} from "../../helpers/addWordBreak";

const TestimonialBlock = ({data, prevType}) => {
    const {langForInUrl} = useCurrentLangCode();

    return (
        <Wrapper $excludeArr={['top', 'bottom']}>
            <S.StyledContainerMedium $paddingTop={prevType === "process"} $paddingBottom={prevType !== "process"}>
                <S.Inner>
                    <S.Content>
                        <S.Decoration src={decoration} alt='' />
                        <S.StyledText dangerouslySetInnerHTML={{__html: parseHTMLText(addWordBreak(data?.text), langForInUrl)}} />
                        <S.Person white>{data?.userName}</S.Person>
                    </S.Content>
                    <S.DecorationCircle src={sliderDecoration} draggable="false" loading="lazy" alt="" />
                </S.Inner>
            </S.StyledContainerMedium>
        </Wrapper>
    )
}

export default TestimonialBlock
