import React from 'react'
import * as S from './TextSlider.elements';
import { HiArrowSmRight } from 'react-icons/hi';
import Heading from "../Heading/Heading";
import Text, {RichText} from "../Text/Text";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from '../../assets/img/about/slider1.jpg';
import slider2 from '../../assets/img/about/slider2.jpg';
import slider3 from '../../assets/img/about/slider3.jpg';

const TextSlider = (props) => {

    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
    };

    return (
        <S.TextSliderContainer $last={props.last}>
            <S.Grid reverse={props.reverse}>
                <S.ContentBox>
                    {props.icon && <S.Icon src={props.icon} alt=''/> }
                    {props.title && <Heading tag="h2">{props.title}</Heading> }
                    {props.text && <RichText dangerouslySetInnerHTML={{__html: props.text}} /> }
                    {props.children && <Text>{props.children}</Text> }
                    {props.object && <RichText>{props.object}</RichText> }
                    {props.buttonUrl && <S.StyledButton primary="true" to={props.buttonUrl}>{props.buttonText} <HiArrowSmRight/></S.StyledButton>}
                </S.ContentBox>
                <S.SliderBox>
                    <S.Outer $center>
                        <S.Inner>
                            <S.StyledSlider {...settings}>
                                <S.Outer>
                                    <S.Inner>
                                        <S.Image src={slider1} />
                                    </S.Inner>
                                </S.Outer>
                                <S.Outer>
                                    <S.Inner>
                                        <S.Image src={slider2} />
                                    </S.Inner>
                                </S.Outer>
                                <S.Outer>
                                    <S.Inner>
                                        <S.Image src={slider3} />
                                    </S.Inner>
                                </S.Outer>
                            </S.StyledSlider>
                        </S.Inner>
                    </S.Outer>
                </S.SliderBox>
            </S.Grid>
        </S.TextSliderContainer>
    )
}

export default TextSlider
