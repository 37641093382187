import React, {useEffect, useState} from 'react'
import * as S from './CasesOverview.elements';
import { HiArrowSmRight, HiChevronDown } from 'react-icons/hi';
import {Await} from "react-router-dom";
import ContainerMedium from "../ContainerMedium/ContainerMedium";
import ContainerSmall from "../ContainerSmall/ContainerSmall";
import Wrapper from "../Wrapper/Wrapper";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";
import CaseCard from "../CaseCard/CaseCard";
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

const CasesOverview = ({data, title, description, buttonLabel, buttonLink, activeId, maxLength, exclude, last = false}) => {
    const {t} = useTranslation();
    const {langForInUrl} = useCurrentLangCode();
    const [count, setCount] = useState(null);
    const [loadedCount, setLoadedCount] = useState(maxLength ? 3 : localStorage.getItem("casesCount") ? JSON.parse(localStorage.getItem("casesCount")) : 6);
    const [hasMoreCases, setHasMoreCases] = useState(true);

    // Data is a promise, so chain to set selected
    useEffect(() => {
        data.then((cases) => {
            setCount(cases.length);

            if (loadedCount >= cases.length) {
                setHasMoreCases(false)
            }
        });
    //eslint-disable-next-line
    }, [data])

    const handleLoadMore = () => {
        setLoadedCount(loadedCount + 6)
        localStorage.setItem("casesCount", JSON.stringify(loadedCount + 6));

        if (loadedCount + 6 >= count) {
            setHasMoreCases(false)
        }
    }

    return (
        <Wrapper $exclude={exclude} last={last}>
            <ContainerSmall as="header">
                <Heading tag="h2">{title}</Heading>
                {description && <Text>{description}</Text>}
            </ContainerSmall>
            <ContainerMedium>
                <S.Grid>
                    <React.Suspense fallback={Array.from({length: loadedCount}, (_, index) => (
                        <CaseCard key={index} loading="true"/>
                    ))}>
                        <Await resolve={data}>
                            {(data) => {
                                let loopData = data?.slice(0, loadedCount);

                                if(activeId) { loopData = data?.filter(obj => obj.id !== activeId)?.slice(0, loadedCount); }

                                return (
                                    loopData.map(item => (
                                        <CaseCard key={item.id} data={item} />
                                    ))
                                )
                            }}
                        </Await>
                    </React.Suspense>
                </S.Grid>
            </ContainerMedium>
            {Boolean(maxLength) &&
                <ContainerSmall>
                    <Button primary="true" to={`${langForInUrl}/${buttonLink || 'cases'}`}>{buttonLabel} <HiArrowSmRight/></Button>
                </ContainerSmall>
            }
            {(!Boolean(maxLength) && hasMoreCases) &&
                <ContainerSmall>
                    <Button secondary="true" as="button" onClick={handleLoadMore} $chevron>{t('general.loadMore_label')} <HiChevronDown/></Button>
                </ContainerSmall>
            }
        </Wrapper>
    )
}

export default CasesOverview
