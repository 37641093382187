import React, {useCallback, useEffect} from 'react';
import styled, {ThemeProvider} from 'styled-components';
import {Helmet} from 'react-helmet-async';
import GlobalStyle from '../../globalStyles';
import {defer, Outlet, ScrollRestoration, useBeforeUnload, useLocation, useNavigation} from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import nProgress from 'nprogress';
import CookieBanner from '../../components/CookieBanner/CookieBanner';
import {useCurrentLangCode} from '../../hooks/useCurrentLangCode';
import {useTranslation} from 'react-i18next';
import {useBreakpointContext} from '../../contexts/BreakpointContext';

const StyledRoot = styled.div`
    /* Minimum height of 101vh so there is always a scrollbar to prevent shifting during load state */
    min-height: 101vh;
`;

export async function rootLoader({request, params}) {
    const lang = params?.lang;

    const data = fetch(`${process.env.REACT_APP_API_URL}/customers${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    const navigationData = fetch(`${process.env.REACT_APP_API_URL}/navigation${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    });

    return defer({data, navigationData});
}

export function shouldNotRevalidateParentOnSearchParamChange({currentUrl, nextUrl, defaultShouldRevalidate}) {
    const currentUrlSearchParamsObj = Object.fromEntries(currentUrl?.searchParams);
    const nextUrlSearchParamsObj = Object.fromEntries(nextUrl?.searchParams);

    // Do not revalidate if on the same page but only searchParams change
    if (currentUrl?.pathname === nextUrl?.pathname) {
        if (Object.keys(currentUrlSearchParamsObj)?.length > 0 || Object.keys(nextUrlSearchParamsObj)?.length > 0) {
            return false;
        }
    }

    return defaultShouldRevalidate;
}

function Root() {
    const location = useLocation();
    const navigation = useNavigation();

    // NProgress bar (loadingbar on page change)
    nProgress.configure({
        showSpinner: false,
        template: '<div class="bar" role="bar"></div>',
        minimum: 0.3,
        speed: 400,
    });

    // Normal navigation in data router is idle -> loading -> idle, so start nProgress if state changed to "loading"
    if(navigation.state === "loading") {
        nProgress.start();
    }

    if(navigation.state === "idle") {
        nProgress.done();
    }

    // When the location changed, complete the nProgress
    useEffect(() => {
        nProgress.done();

        // Remove blogsCount from localStorage when navigating
        if(!location?.pathname?.startsWith("/blog")) {
            localStorage.removeItem("blogsCount");
        }

        // Remove casesCount from localStorage when navigating
        if(!location?.pathname?.startsWith("/cases")) {
            localStorage.removeItem("casesCount");
        }
    }, [location])

    // Remove blogsCount & casesCount from localStorage when navigating away from site
    useBeforeUnload(
        useCallback(() => {
            localStorage.removeItem("blogsCount");
            localStorage.removeItem("casesCount");
        }, [])
    )

    // Change i18n language on first render
    const {i18n, t} = useTranslation();
    const {currentLangCode} = useCurrentLangCode();

    useEffect(() => {
        if(Boolean(currentLangCode)) i18n.changeLanguage(currentLangCode.code);
        //eslint-disable-next-line
    }, []);

    // Initial breakpoint is 1024px, but could be different when logo shrinks when different language text is too wide. Breakpoint is updated in NavBar and set in theme ctx to use in Navbar and PricingTable etc.
    const {breakpoint} = useBreakpointContext();
    const theme = {
        bp: breakpoint,
    }

    return (
        <StyledRoot>
            <Helmet>
                <title>Youmie &mdash; {t('general.title')}</title>
            </Helmet>

            <GlobalStyle />
            <ScrollRestoration />
            <ThemeProvider theme={theme}>
                <Navbar />
                <Outlet />
                <Footer />
                <CookieBanner />
            </ThemeProvider>
        </StyledRoot>
    );
}

export default Root;