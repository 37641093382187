import styled, { css, keyframes } from 'styled-components';
import Button from "../../components/Button/Button";

export const ContactContainer = styled.div`
    margin-top: -300px;
    position: relative;
    margin-bottom: 80px;
    z-index: 95;
`;

export const Flex = styled.div`
    display: flex;
    margin: 0 -10px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0;
    }
`;

const contentStyles = css`
    background-color: var(--color-white);
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.05);
    margin: 0 10px;

    @media screen and (max-width: 992px) {
        padding: 24px;
    }

    @media screen and (max-width: 768px) {
        margin: 0;
    }
`;

export const Info = styled.section`
    flex: 1;
    ${contentStyles};

    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

export const Form = styled.section`
    flex: 2;
    ${contentStyles};
`;

const pulse = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(75,193,122, 0.4);
    }
    70% {
        box-shadow: 0 0 0 30px rgba(75,193,122, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(75,193,122, 0);
    }
`;

export const DoubleInput = styled.div`
    display: flex;
    margin: 0 -8px;
    margin-top: 32px;
    
    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
`;

export const InputSet = styled.div`
    flex: 1;
    margin: 0 8px;
`;

export const Submitted = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
    height: 100%;
`;

export const Check = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 rgba(75,193,122, 0.4);
    margin-bottom: 28px;
    animation: ${pulse} 2s infinite;

    svg {
        color: var(--color-white);
        width: 30px;
        height: 30px;
    }
`;

export const StyledButton = styled(Button)`
    display: block;
    margin-left: auto;
`;

export const List = styled.ul`
    padding: 32px 0;

    @media screen and (max-width: 768px) {
        padding-bottom: 20px;
    }
`;

export const Item = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    img {
        width: 50px;
        height: 50px;
        margin-right: 16px;
    }
`;

export const IconList = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    
    a {
        display: flex;
        text-decoration: none;
        color: inherit;
        margin: 0;
        
        svg {
            font-size: 3.2rem;
            color: var(--color-text);
        }
    }
`;