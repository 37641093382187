import styled from 'styled-components';

export const Card = styled.article`
    background-color: var(--color-white);
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    margin: ${props => props.margin || '8px'};

    @media screen and (max-width: 992px) {
        padding: 24px;
    }
`;

export const Figure = styled.figure`
    margin-bottom: 16px;
`;

export const Img = styled.img`
    width: 50px;
    height: 50px;
`;