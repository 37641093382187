import {useRef} from "react";
import {useDropdown} from "../../hooks/useDropdown";
import {Await, NavLink, useMatch, useRouteLoaderData} from "react-router-dom";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {useTranslation} from "react-i18next";
import styled, {css} from "styled-components";
import {StyledNavLink} from "./Navbar.elements";
import {Transition} from "react-transition-group";
import {LuChevronDown} from "react-icons/lu";
import Heading from "../Heading/Heading";
import React from "react";
import {useChangeNavbarOnScroll} from "../../hooks/useChangeNavbarOnScroll";
import {YoumieSpinner} from "../Loading/Loading";

const DropdownWrapper = styled.div`
    position: relative;
`;

const NavButton = styled(StyledNavLink)`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const StyledChevron = styled(LuChevronDown)`
    width: 22px;
    height: 22px;
    transition: transform .2s ease;

    ${({$open}) => $open && css`
        transform: rotate(-180deg);
    `}

    ${StyledNavLink}:hover & {
        transform: rotate(-180deg);
    }
`;

const Dropdown = styled.div`
    position: absolute;
    padding-top: ${({$scrolled}) => $scrolled ? '44px' : '50px'};
    top: 20px;
    left: 50%;
    transform: translateX(calc(-50% + 8px));
    width: 370px;
    max-height: calc(100vh - 100px);
    z-index: 1;
    opacity: 0;
    transition: opacity .2s ease, transform .2s ease, padding-top .2s ease;
    transform-origin: top left;
    
    &.entering { opacity: 1; transform: scale(1) translateX(calc(-50% + 8px)); }
    &.entered { opacity: 1; transform: scale(1) translateX(calc(-50% + 8px)); }
    &.exiting { opacity: 0; transform: scale(.95) translateX(calc(-50% + 8px)); }
    &.exited { opacity: 0; transform: scale(.95) translateX(calc(-50% + 8px)); }
    
    @media screen and (max-width: 768px) {
        left: 0;
    }
`;

const InnerDropdown = styled.div`
    padding: 30px 20px 20px;
    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.1);
    height: 100%;
    overflow-y: auto;
    text-align: left;
`;

const DropdownList = styled.div`
    margin-top: 8px;
`;

const DropdownItem = styled(NavLink)`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    border-radius: 10px;
    cursor: pointer;
    background-color: ${({$active}) => $active ? 'var(--color-dropdown)' : 'transparent'};

    &:not(:last-of-type) {
        margin-bottom: 4px;
    }
    
    &:hover {
        background-color: var(--color-dropdown);
    }
    
    &.active {
        background-color: var(--color-dropdown);
    }
`;

const DropdownIcon = styled.img`
    display: block;
    width: 40px;
    height: 40px;
    object-fit:  cover;
    flex-shrink: 0;
`;

const DropdownContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const DropdownTitle = styled.p`
    font-family: var(--font-button);
    font-weight: var(--fw-bold);
    line-height: 1.25;
    font-size: var(--fs-nav-dropdown);
    color: var(--color-heading-on-bg);
    text-decoration: none;
`;

const DropdownSubtitle = styled.p`
    line-height: 1.25;
    font-size: var(--fs-nav-dropdown);
    color: var(--color-text-on-bg);
    text-decoration: none;
`;

export const NavbarDropdown = ({label, closeMenu}) => {
    const {t} = useTranslation();
    const {langForInUrl} = useCurrentLangCode();
    const {navigationData} = useRouteLoaderData("root");

    const dropdownRef = useRef(null);
    const nodeRef = useRef(null);
    const {open, setOpen} = useDropdown(dropdownRef);
    const {scrolled} = useChangeNavbarOnScroll();
    const match = useMatch("/:lang?/prijzen/:pricingPageType?/:slug?");

    const selectItem = () => {
        setOpen(false);
        closeMenu();
    }

    const handleNavClick = () => {
        setOpen(true);
    }

    return (
        <DropdownWrapper ref={dropdownRef}>
            <NavButton as="button" className={match ? "active" : ""} onClick={handleNavClick}>{label} <StyledChevron $open={open} /></NavButton>

            <Transition nodeRef={nodeRef} in={open} timeout={200} unmountOnExit>
                {state => (
                    <Dropdown ref={nodeRef} className={state} $scrolled={scrolled}>
                        <InnerDropdown>
                            <Heading tag="h4">{t('navbar.pricing_dropdown_label')}</Heading>
                            <React.Suspense fallback={<YoumieSpinner />}>
                                <Await resolve={navigationData}>
                                    {(navigationData) => (
                                        <DropdownList>
                                            {navigationData?.map(item => (
                                                <DropdownItem key={item.id} to={`${langForInUrl}/prijzen/${item.slug}`} onClick={selectItem}>
                                                    {item.icon && <DropdownIcon src={item.icon} alt="" />}
                                                    <DropdownContent>
                                                        <DropdownTitle>{item.title}</DropdownTitle>
                                                        {item.subtitle && <DropdownSubtitle>{item.subtitle}</DropdownSubtitle>}
                                                    </DropdownContent>
                                                </DropdownItem>
                                            ))}
                                        </DropdownList>
                                    )}
                                </Await>
                            </React.Suspense>
                        </InnerDropdown>
                    </Dropdown>
                )}
            </Transition>
        </DropdownWrapper>
    );
}