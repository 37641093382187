import React from 'react';
import * as S from './CasesDetail.elements';
import Hero from "../../components/Hero/Hero";
import Container from "../../components/Container/Container";
import TriplePhone from "../../components/TriplePhone/TriplePhone";
import FeatureGrid from "../../components/FeatureGrid/FeatureGrid";
import Feature from "../../components/Feature/Feature";
import CustomerSlider from "../../components/CustomerSlider/CustomerSlider";
import Process from "../../components/Process/Process";
import TextImage from "../../components/TextImage/TextImage";
import BulletSection from "../../components/BulletSection/BulletSection";
import Bullet from "../../components/Bullet/Bullet";
import {defer, useLoaderData, useRouteLoaderData} from "react-router-dom";
import CasesOverview from "../../components/CasesOverview/CasesOverview";
import {Seo} from "../../components/Seo/Seo";
import {useTranslation} from "react-i18next";
import TestimonialBlock from "../../components/TestimonialBlock/TestimonialBlock";

export async function casesDetailLoader({request, params}) {
    const lang = params?.lang;

    const contactPageData = await fetch(`${process.env.REACT_APP_API_URL}/contact-page${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }

        return res.json();
    })

    const customerData = await fetch(`${process.env.REACT_APP_API_URL}/customers/${params?.slug}${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Page is not found", { status: 404 });
        }
        return res.json();
    });

    const approachData = fetch(`${process.env.REACT_APP_API_URL}/approach${Boolean(lang) ? `?lang=${lang}` : ''}`, {
        signal: request.signal
    }).then((res) => {
        if(!res.ok) {
            throw new Response("Bad request", { status: 400 });
        }
        return res.json();
    });

    return defer({contactPageData, customerData, approachData});
}

const CasesDetail = () => {
    const {t} = useTranslation();
    const {data: cases} = useRouteLoaderData("root");
    const {contactPageData, customerData, approachData} = useLoaderData();
    let prevType = null;

    return (
        <>
            <Seo
                title={customerData?.title}
                description={customerData?.sections?.filter((section) => section.type === 'header')[0]?.text}
                slug={`/cases/${customerData?.slug}`}
                image={customerData?.imageUrl}
            />

            {customerData?.sections?.length > 0 && customerData.sections.map(item => {

                if(item.type === 'header') {
                    prevType = 'header';

                    return (
                        <div key={item.id}>
                            <Hero
                                type="customer"
                                heading={item.title}
                                text={item.text}
                            />

                            <S.CustomerContainer>
                                <Container>
                                    <S.Img src={item.imageUrl} alt="" />
                                </Container>
                            </S.CustomerContainer>
                        </div>
                    )
                }

                if(item.type === 'triplePhone') {
                    prevType = 'triplePhone';

                    return (
                        <TriplePhone
                            key={item.id}
                            title={item.title}
                            text={item.text}
                            image={item.imageUrl}
                        />
                    )
                }

                if(item.type === 'featureGrid') {
                    prevType = 'featureGrid';

                    return (
                        <FeatureGrid
                            key={item.id}
                            title={item.title}
                            description={item.text}
                        >
                            {item.items.map(feature => {
                                return (
                                    <Feature
                                        key={feature.id}
                                        heading={feature.title}
                                        description={feature.text}
                                        image={feature.imageUrl}
                                        alt=""
                                    />
                                )
                            })}
                        </FeatureGrid>
                    )
                }

                if(item.type === 'slider') {
                    prevType = 'slider';

                    return (
                        <CustomerSlider key={item.id} data={contactPageData} />
                    )
                }

                if(item.type === 'testimonial') {
                    let prevTypeProp = prevType;
                    prevType = 'testimonial';

                    return (
                        <TestimonialBlock key={item.id} data={item} prevType={prevTypeProp} />
                    )
                }

                if(item.type === 'process') {
                    prevType = 'process';

                    return (
                        <Process key={item.id} data={approachData}/>
                    )
                }

                if(item.type === 'textImage') {
                    prevType = 'textImage';

                    return (
                        <TextImage key={item.id} image={item.imageUrl} title={item.title} text={item.text} buttonText={item.buttonText} buttonUrl={item.buttonUrl} reverse={item.reversed}/>
                    )
                }

                if(item.type === 'bullet') {
                    prevType = 'bullet';

                    return (
                        <BulletSection key={item.id} title={item.title} description={item.text} image={item.decorationImageUrl} mockup={item.imageUrl}>
                            {item.items.map(bullet => {
                                return (
                                    <Bullet
                                        key={bullet.id}
                                        heading={bullet.title}
                                        description={bullet.text}
                                    />
                                )
                            })}
                        </BulletSection>
                    )
                }

                if(item.type === 'otherCases') {
                    prevType = 'otherCases';

                    return (
                        <CasesOverview
                            key={item.id}
                            data={cases}
                            title={t('cases.otherCases_title')}
                            buttonLabel={contactPageData[0]?.casesButtonLabel}
                            buttonLink={contactPageData[0]?.casesButtonLink}
                            activeId={cases?.id}
                            maxLength={true}
                            last={true}
                        />
                    )
                }

                return null;
            })}
        </>
    )
}

export default CasesDetail