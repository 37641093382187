import React, { useState, useEffect } from 'react'
import * as S from './IntegrationParallax.elements';
import { HiArrowSmRight } from "react-icons/hi";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Await} from "react-router-dom";
import Container from "../Container/Container";
import Heading from "../Heading/Heading";
import {RichText} from "../Text/Text";
import Button from "../Button/Button";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {parseHTMLText} from "../../helpers/parseHTMLText";

const IntegrationParallax = ({data}) => {
    const {langForInUrl} = useCurrentLangCode();

    // Handle parallax on mobile
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if(window.innerWidth <= 1024) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <S.Wrapper>
            <Container>
                <S.Flex>
                    <S.Box>
                        <S.IntegrationCloud>
                            <S.Logos>
                                <S.ParallaxItem as={mobile ? "div" : ""} speed={-20}>
                                    <React.Suspense fallback={<Loading />}>
                                        <Await resolve={data}>
                                            {(data) => (
                                                data[0]?.partners?.slice(0, 7).map(item => (
                                                    <S.Figure key={item.id}>
                                                        <S.Logo src={item.image} alt=""/>
                                                    </S.Figure>
                                                ))
                                            )}
                                        </Await>
                                    </React.Suspense>
                                </S.ParallaxItem>
                                <S.ParallaxItem className="parallax--margin" as={mobile ? "div" : ""} speed={20}>
                                    <React.Suspense fallback={<Loading />}>
                                        <Await resolve={data}>
                                            {(data) => (
                                                data[0]?.partners?.slice(7, 14).map(item => (
                                                    <S.Figure key={item.id}>
                                                        <S.Logo src={item.image} alt=""/>
                                                    </S.Figure>
                                                ))
                                            )}
                                        </Await>
                                    </React.Suspense>
                                </S.ParallaxItem>
                                <S.ParallaxItem as={mobile ? "div" : ""} speed={-20}>
                                    <React.Suspense fallback={<Loading />}>
                                        <Await resolve={data}>
                                            {(data) => (
                                                data[0]?.partners?.slice(14, 21).map(item => (
                                                    <S.Figure key={item.id}>
                                                        <S.Logo src={item.image} alt=""/>
                                                    </S.Figure>
                                                ))
                                            )}
                                        </Await>
                                    </React.Suspense>
                                </S.ParallaxItem>
                            </S.Logos>
                        </S.IntegrationCloud>
                    </S.Box>
                    <React.Suspense fallback={<Loading />}>
                        <Await resolve={data}>
                            {(data) => (
                                <S.TextBox>
                                    <Heading tag="h2" textOnSurface>{data[0]?.title}</Heading>
                                    <RichText textOnSurface dangerouslySetInnerHTML={{__html: parseHTMLText(data[0]?.text, langForInUrl)}} />
                                    <Button primary="true" to={`${langForInUrl}/${data[0]?.buttonLink}`}>{data[0]?.buttonLabel} <HiArrowSmRight/></Button>
                                </S.TextBox>
                            )}
                        </Await>
                    </React.Suspense>
                </S.Flex>
            </Container>
        </S.Wrapper>
    )
}

const Loading = () => {
    return (
        Array.from({ length: 7 }, (_, index) => (
            <S.Figure key={index}>
                <Skeleton circle />
            </S.Figure>
        ))
    )
}

export default IntegrationParallax
