import React from 'react'
import * as S from './AtmosphericHero.elements';
import Heading from "../../components/Heading/Heading";
import Text from "../../components/Text/Text";
import cases1 from "../../assets/img/blocks/atmosphericHero/cases1.jpg";
import cases2 from "../../assets/img/blocks/atmosphericHero/cases2.jpg";
import blogs1 from "../../assets/img/blocks/atmosphericHero/blogs1.jpg";
import blogs2 from "../../assets/img/blocks/atmosphericHero/blogs2.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AtmosphericHero = ({title, text, location}) => {

    const blogsAndLabsSlides = [
        {"id": 1, "image": blogs1},
        {"id": 2, "image": blogs2},
    ];

    const casesSlides = [
        {"id": 1, "image": cases1},
        {"id": 2, "image": cases2},
    ];

    const data = location === "blogs" ? blogsAndLabsSlides : casesSlides;

    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
    };

    return (
        <S.StyledAtmosphericHero>
            <S.Outer>
                <S.Inner>
                    <S.Flex>
                        <S.Card>
                            <Heading tag="h1" $noMargin>{title}</Heading>
                            <Text>{text}</Text>
                        </S.Card>
                    </S.Flex>

                    <S.StyledSlider {...settings}>
                        {data?.map(item => (
                            <S.Image key={item.id} src={item.image} />
                        ))}
                    </S.StyledSlider>
                </S.Inner>
            </S.Outer>

            <svg viewBox="0 0 500 40" preserveAspectRatio="none">
                <path d="M0 40 0 0Q250 40 500 0L500 40Z" fill="#FEFEFE" />
            </svg>
        </S.StyledAtmosphericHero>
    )
}

export default AtmosphericHero