import * as S from './Input.elements';
import { useField } from "formik";

const Input = ({ label, ...props }) => {

    const [field, meta] = useField(props);

    return (
        <>
            <S.Label htmlFor={props.id || props.name}>{label}</S.Label>
            <S.Input className="text-input" {...field} {...props} />
            {meta.touched && meta.error ? (
                <S.Error className="error">{meta.error}</S.Error>
            ) : null}
        </>
    )
}

export default Input