import {createContext, useContext, useState} from "react";

export const BreakpointContext = createContext(null);

export default function BreakpointProvider({children}) {
    const [breakpoint, setBreakpoint] = useState(1024);

    return (
        <BreakpointContext.Provider value={{breakpoint, setBreakpoint}}>
            {children}
        </BreakpointContext.Provider>
    )
}

export const useBreakpointContext = () => useContext(BreakpointContext);