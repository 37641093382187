import React from 'react'
import * as S from './CaseCard.elements';
import { HiArrowSmRight } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import Heading from "../Heading/Heading";
import Text from "../Text/Text";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";

const CaseCard = ({data, loading}) => {
    const {langForInUrl} = useCurrentLangCode();

    if(loading) {
        return (
            <S.StyledCaseCard>
                <S.SkeletonFigure>
                    <Skeleton height="200px" width="100%" />
                </S.SkeletonFigure>

                <S.InnerCaseCard>
                    <Heading tag="h4"><Skeleton width={120} /></Heading>
                    <Text><Skeleton width={70} count={3} /></Text>
                    <S.ReadMore>
                        <S.ReadMoreText><Skeleton width={120} /></S.ReadMoreText>
                    </S.ReadMore>
                </S.InnerCaseCard>
            </S.StyledCaseCard>
        )
    }

    return (
        <S.StyledCaseCard as={!Boolean(data.hasDetailPage) ? 'article' : undefined} to={`${langForInUrl}/cases/${data.slug}`} $isLink={Boolean(data.hasDetailPage)}>
            {data.imageUrl &&
                <S.OuterImage>
                    <S.InnerImage src={data.imageUrl} alt="" />
                </S.OuterImage>
            }
            <S.InnerCaseCard>
                <Heading tag="h4">{data.title}</Heading>
                <Text $truncate $clamp="3">{data.text}</Text>
                <S.ReadMore>
                    <S.ReadMoreText>{data?.buttonText ?? "Lees meer"} {Boolean(data.hasDetailPage) && <HiArrowSmRight/>}</S.ReadMoreText>
                </S.ReadMore>
            </S.InnerCaseCard>
        </S.StyledCaseCard>
    )
}

export default CaseCard
