import React, { useState } from 'react'
import { HiArrowSmRight } from 'react-icons/hi';
import * as S from './HomeHero.elements';
import { AnimateSharedLayout } from "framer-motion";
import borderCircles from '../../assets/img/decorations/deco-border-circles.svg';
import preview1 from '../../assets/img/blocks/homeHero/preview1.jpg';
import preview2 from '../../assets/img/blocks/homeHero/preview2.jpg';
import preview3 from '../../assets/img/blocks/homeHero/preview3.jpg';
import preview4 from '../../assets/img/blocks/homeHero/preview4.jpg';
import preview5 from '../../assets/img/blocks/homeHero/preview5.jpg';
import preview6 from '../../assets/img/blocks/homeHero/preview6.jpg';
import logo1 from '../../assets/img/blocks/homeHero/logo1.jpg';
import logo2 from '../../assets/img/blocks/homeHero/logo2.jpg';
import logo3 from '../../assets/img/blocks/homeHero/logo3.jpg';
import logo4 from '../../assets/img/blocks/homeHero/logo4.jpg';
import logo5 from '../../assets/img/blocks/homeHero/logo5.jpg';
import logo6 from '../../assets/img/blocks/homeHero/logo6.jpg';
import Heading from "../Heading/Heading";
import Container from "../Container/Container";
import Text from "../Text/Text";
import Button from "../Button/Button";
import {useCurrentLangCode} from "../../hooks/useCurrentLangCode";
import {addWordBreakInSpan} from "../../helpers/addWordBreak";

const HomeHero = ({data}) => {
    const [selected, setSelected] = useState(options[0]);
    const {langForInUrl} = useCurrentLangCode();

    return (
        <S.Hero>
            <Container>
                <S.Flex>
                    <S.HeroText>
                        <Heading textOnSurface tag="h1">{renderFormattedText(data[0]?.title)}</Heading>
                        <Text textOnSurface>{data[0]?.text}</Text>
                        <S.ButtonWrapper>
                            <Button primary="true" to={`${langForInUrl}/${data[0]?.primaryButtonLink}`}>{data[0]?.primaryButtonLabel} <HiArrowSmRight/></Button>
                            <Button surface="true" to={`${langForInUrl}/${data[0]?.secondaryButtonLink}`}>{data[0]?.secondaryButtonLabel} <HiArrowSmRight/></Button>
                        </S.ButtonWrapper>
                    </S.HeroText>

                    <S.DemoPhone>
                        <S.PhoneWrapper>
                            <S.Preview src={selected.image} alt="" />
                            <AnimateSharedLayout>
                                <S.OptionList>
                                    {options.map(item => (
                                        <Item
                                            key={item.id}
                                            color={item}
                                            isSelected={selected === item}
                                            onClick={() => setSelected(item)}
                                        />
                                    ))}
                                </S.OptionList>
                            </AnimateSharedLayout>
                        </S.PhoneWrapper>
                    </S.DemoPhone>
                </S.Flex>
            </Container>

            <S.DecorationBorderCircles src={borderCircles} alt="" draggable="false" />
        </S.Hero>
    )
}

const renderFormattedText = (data) => (
    <>
        {data.split(/<span>|<\/span>/).map((part, index) => (
            index % 2 === 0 ? addWordBreakInSpan(part, index + 1) : (
                <span dangerouslySetInnerHTML={{ __html: `<span class="underline">${part}</span>` }} key={index + 1} />
            )
        ))}
    </>
);

function Item({ color, isSelected, onClick }) {
    return (
        <S.StyledItem className="item" onClick={onClick} style={{ backgroundColor: `rgb(${color.primary})` }}>
            <S.ItemImage src={color?.logo} alt="" />
            {isSelected && (
                <S.Outline
                    layoutId="outline"
                    className="outline"
                    initial={false}
                    animate={{ borderColor: `rgb(${color.primary})` }}
                    transition={spring}
                />
            )}
        </S.StyledItem>
    );
}

const options = [
    { "id": "1", "primary": "75, 193, 122", "image": preview1, "logo": logo1 },
    { "id": "2", "primary": "150, 35, 41", "image": preview2, "logo": logo2 },
    { "id": "3", "primary": "46, 101, 168", "image": preview3, "logo": logo3 },
    { "id": "4", "primary": "2, 96, 143", "image": preview4, "logo": logo4 },
    { "id": "5", "primary": "1, 132, 62", "image": preview5, "logo": logo5 },
    { "id": "6", "primary": "10, 10, 10", "image": preview6, "logo": logo6 },
]

const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};

export default HomeHero