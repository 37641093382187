import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import Container from "../Container/Container";
import Button from "../Button/Button";

export const StyledNavbar = styled.nav`
    position: fixed;
    z-index: 98;
    width: 100%;
    background-color: var(--color-white);
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.1);
    padding-top: 16px;
    padding-bottom: 16px;
    transition: padding .2s ease;

    @media screen and (min-width: ${({theme}) => `${theme.bp + 1}px`}) {
        padding-top: ${props => props.scrolled ? '18px' : '24px'};
        padding-bottom: ${props => props.scrolled ? '18px' : '24px'};
    }
`;

export const NavContainer = styled(Container)`
    display: flex;
    align-items: center;
`;

export const Logo = styled.img`
    display: block;
    width: 150px;
    max-width: 100%;
    height: 50px;
    object-fit: contain;
    margin-right: 10px;
`;

export const Menu = styled.div` 
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    
    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        justify-content: space-between;
        display: block;
        position: absolute;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 30px 48px;
        top: 60px;
        left: ${({ isOpen }) => (isOpen ? 0 : '-100%')};
        text-align: left;
        width: 100%;
        min-height: calc(100vh - 67px);
        height: 100%;
        opacity: 1;
        transition: all .2s ease;
        background-color: var(--color-white);
        font-family: var(--font-heading);
    }

    @media screen and (max-width: 768px) {
        padding: 30px 24px;
    }

    @media screen and (max-width: 425px) {
        padding: 30px 16px;
    }
`;

export const ExtraBox = styled.ul`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        display: none;
    }
`;

export const MenuBox = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        display: block;
        text-align: left;
    }
`;

export const Item = styled.li`
    position: relative;

    button {
        outline: revert;
    }

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        margin-bottom: 20px;
    }
`;

export const StyledNavLink = styled(NavLink)`
    cursor: pointer;
    font-size: var(--fs-nav-link);
    color: var(--color-text-on-bg);
    font-weight: var(--fw-regular);
    text-decoration: none;
    padding: 4px 12px;
    height: 100%;
    user-select: text;
    transition: color .2s ease;

    ${props => props.$onlyMobile && `
        display: none;
        
        @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
            display: inline;
        }
    `};

    @media screen and (min-width: ${({theme}) => `${theme.bp + 1}px`}){

        &.active {
            font-weight: var(--fw-semi-bold);
            
            &:hover {
                color: var(--color-text-on-bg);
            }

            &:after {
                content: '';
                position: absolute;
                bottom: -12px;
                left: 50%;
                transform: translateX(-50%);
                width: 8px;
                height: 8px;
                background-color: var(--color-primary);
                border-radius: 50%;
                transition: .2s all ease;
            }
        }
        
        &:hover {
            color: var(--color-text-on-bg-hover);
        }
    }

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        color: var(--color-heading-on-surface);
        font-weight: var(--fw-bold);
        width: 100%;
        padding: 0;
    }

    svg {
        vertical-align: middle;
        transition: transform .2s ease;

        &[aria-expanded='true'] {
            transform: rotate(180deg);
        }
    }

    &[aria-expanded='true'] {
        svg {
            transform: rotate(180deg);
        }
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(340px, 1fr));
`;

export const ContactList = styled.ul`
    display: none;

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        display: block;
        color: var(--color-text-on-surface);
        font-size: var(--font-size-nav-contact);
        line-height: 1.5;
        border-top: 1px solid #E7E7E7;
        margin-top: 40px;
        padding-top: 40px;

        svg {
            color: var(--color-primary);
            width: 24px;
            height: 24px;
        }

        li {
            margin-bottom: 6px;
        }

        a {
            display: grid;
            grid-template-columns: 24px 100%;
            gap: 20px;
        }
    }
`;

export const MobileOnly = styled.div`
    display: none;

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        display: block;
    }
`;

export const IconList = styled.div`
    display: none;

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        margin-top: 24px;
        margin-bottom: 80px;
    
        a {
            text-decoration: none;
            color: inherit;
            margin: 0;
            
            svg {
                font-size: 2.2rem;
                color: var(--color-primary);
            }
        }
    }
`;

export const StyledButton = styled(Button)`
    margin: 0 0 0 12px;
    flex-shrink: 0;

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        margin: 0;
    }
`;

export const HamburgerWrapper = styled.div`
    display: none;

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 10px;
    }
`;

export const HamburgerIcon = styled.div` 
    display: none;

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        display: block;
    }

    span {
        display: block;
        border-radius: 4px;
        height: 3px;
        width: 21px;
        background-color: var(--color-text-on-bg);
        transition: transform .2s ease, opacity .2s ease;

        &:nth-child(1) {
            transform-origin: top right;
            transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
        } 
        
        &:nth-child(2) {
            opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
        }
        
        &:nth-child(3) {
            transform-origin: bottom right;
            transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
        }

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
`;

export const DecorationBorderCircles = styled.img`
    display: none;

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        display: block;
        position: absolute;
        bottom: 0;
        right: -160px;
        width: 500px;
        height: 500px;
        transition: width .2s ease, height .2s ease, bottom .2s ease, right .2s ease;
    }

    @media screen and (max-width: ${({theme}) => `${theme.bp}px`}) {
        width: 400px;
        height: 400px;
    }

    @media screen and (max-width: 475px) {
        width: 350px;
        height: 350px;
    }

    @media screen and (max-width: 425px) {
        right: -200px;
        bottom: -70px;
        width: 350px;
        height: 350px;
    }
`;